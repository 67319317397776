import { nextTick } from "vue";
import _ from "underscore";
import { gradualColor, colList, colWhite } from "common/utl/config";

let screenObj = {}, //存储大屏数据变量
  otherObj = {}, //其他数据
  flagObj = {}, //状态变量
  refObj = {}, //代理对象
  optConfig = {},
  staticObj = {}; //静态数据

let storeGlobal = null,
  storePage = null,
  commonAxios = null,
  commonApi = null,
  mutation = null,
  message = null;

//构造函数赋值
const init = config => {
  screenObj = config.screenObj;
  otherObj = config.otherObj;
  flagObj = config.flagObj;
  refObj = config.refObj;
  staticObj = config.staticObj;
  optConfig = config.optConfig;
  storeGlobal = config.storeGlobal;
  storePage = config.storePage;
  commonAxios = config.commonAxios;
  commonApi = config.commonApi;
  mutation = config.mutation;
  message = config.$message;
};

const getScreenData = () => {
  return {
    screenObj: screenObj,
    otherObj: otherObj,
    flagObj: flagObj,
    refObj: refObj,
    staticObj: staticObj
  };
};

const setScreenCompData = config => {
  screenObj = config.screenObj;
  otherObj = config.otherObj;
  flagObj = config.flagObj;
  refObj = config.refObj;
  staticObj = config.staticObj;
};

//初始化大屏数据
const adaptInitComp = (data, store, isPreview = false, callback) => {
  getAllPrjModels().then(() => {
    screenObj.screen.value = data;
    screenObj.screen.value.prjId = otherObj.project.value.id;
    
    screenObj.pageInfos.value = [];
    if(callback) {
      callback();
    }
  });
};

const dealInitData = obj => {
  if (obj.type == "custom-layout1" || obj.type == "dynamic-board") {
    refObj.repeatWidths[obj.id] = {
      width: obj.style.width,
      height: obj.style.height
    };
    refObj.recordDynamic.value[obj.id] = JSON.parse(
      JSON.stringify(obj.children)
    );
    let result = adaptDataFn(obj, obj.basicConfigData);

    if (obj.type == "dynamic-board") {
      refObj.parentParams.value[obj.id] = {
        data: result
      };
      if (obj.parent) {
        refObj.parentParams.value[obj.id].pid = obj.parent.pid;
      }
    } else refObj.parentParams.value[obj.id] = result;
  } else if (obj.type == "layout") {
    refObj.layoutWidths[obj.id] = obj.style.width;
    refObj.recordRows[obj.id] = obj.layoutDivision;
    obj.repeatLayout = [];
    let result = adaptDataFn(obj, obj.basicConfigData);
    refObj.parentParams.value[obj.id] = result;
  }
};

//移动卡顿问题所在，TODO 待优化
const setComInfoList = (type, obj) => {
  switch(type) {
    case 1:
      let obj2 = _.pick(
        obj,
        "id",
        "name",
        "active",
        "children",
        "type",
        "style",
        "disabled",
        "lock",
        "parent"
      );
      if (!flagObj.dynamicEdit.value && !flagObj.repeatCompEdit.value) {
        refObj.compInfoList.value.push(obj2);
      }else if(refObj.compInfoList.value.length > 0){
        let list = repeatFn(refObj.compInfoList.value, v=> {
          if(v.id == refObj.dynamicComp.value.id) {
            if(refObj.dynamicComp.value.children) {
              let nodeList = repeatFn(refObj.dynamicComp.value.children, c => {
                let obj3 = _.pick(
                  c,
                  "id",
                  "name",
                  "active",
                  "children",
                  "type",
                  "style",
                  "disabled",
                  "lock",
                  "parent"
                );
                return obj3;
              })
              v.children = [...nodeList];
            }
          }
          return v;
        })
        refObj.compInfoList.value = list;
      }
      
      
      break;
    case 2: 
      let list = repeatFn(refObj.compInfoList.value, v => {
        if(v.id == obj.id) {
          v = {...obj};
        }
        return v;
      })
      refObj.compInfoList.value = list;
      break;
    case 3: 
      let index = _.findIndex(refObj.compInfoList.value, e => {return e.id == obj.id});
      if(index != -1) {
        refObj.compInfoList.value.splice(index, 1);
      }else {
        refObj.compInfoList.value = repeatComData(refObj.compInfoList.value, obj.id);
      }
      
    break;
    default: 
      let info = [];
      obj.forEach(v => {
        let obj2 = _.pick(
          v,
          "id",
          "name",
          "active",
          "children",
          "type",
          "style",
          "disabled",
          "lock",
          "parent"
        );
        info.push(obj2);
      })
      refObj.compInfoList.value = [...info];
    break;
  }
};

const getCompParams = v => {
  let data = v.transformData;
  if (!v.transformData) {
    if (v.type == "chart" || v.type == "static" || v.type == "progress2" || v.type == 'choropleth') {
      if (typeof v.option == "object") {
        data = JSON.stringify(v.option);
      } else {
        data = v.option;
      }
      v.transformData = v.option;
    } else if (v.config) {
      if (typeof v.config == "object") {
        data = JSON.stringify(v.config);
      } else {
        data = v.config;
      }
      v.transformData = v.config;
    } else if (v.type == "clock") {
      data = JSON.stringify({
        format: v.format,
        isWeek: !v.isWeek ? false : true
      });
      v.transformData = JSON.parse(data);
    } else {
      if (typeof v.sourceData.sourceData == "object") {
        data = JSON.stringify(v.sourceData.sourceData);
      } else {
        data = v.sourceData.sourceData;
      }
      v.transformData = v.sourceData.sourceData;
    }
  } else {
    if (typeof v.transformData == "object") {
      data = JSON.stringify(v.transformData);
    } else {
      data = v.transformData;
    }
  }

  let params;
  if (v.parent && refObj.parentParams.value[v.parent.pid]) {
    params = refObj.parentParams.value[v.parent.pid];
    if (
      !refObj.parentParams.value[v.parent.pid].isVertical &&
      (v.parent.x == 0 || v.parent.x)
    )
      params.x =
        v.parent.x -
          (refObj.parentParams.value[v.parent.pid]
            ? refObj.parentParams.value[v.parent.pid].rowIndex
            : 0) <
        0
          ? 0
          : v.parent.x - refObj.parentParams.value[v.parent.pid].rowIndex;
    else if (v.parent.y == 0 || v.parent.y)
      params.y =
        v.parent.y -
          (refObj.parentParams.value[v.parent.pid]
            ? refObj.parentParams.value[v.parent.pid].colIndex
            : 0) <
        0
          ? 0
          : v.parent.y - refObj.parentParams.value[v.parent.pid].colIndex;
    else if (v.parent.index == 0 || v.parent.index)
      params.index = v.parent.index;
  }
  data = adaptParamData(data, v.params, params);
  if (typeof v.transformData == "object") {
    data = JSON.parse(data);
  }
  v.resultData = data;

  adaptCompData(v);
};

const getAllPrjModels = () => {
  return new Promise((res, rej) => {
    commonApi.share
      .getAllModels({ prjId: otherObj.project.value.id })
      .then(data => {
        screenObj.prjModelList.value = data;
        screenObj.prjModelList.value.forEach(v => {
          adaptModelData(v);
        });
        if (mutation) mutation.setPrjModelList(screenObj.prjModelList.value);
        res();
      });
  });
};

const getModelContentById = id => {
  return new Promise((res, rej) => {
    let model = _.find(screenObj.prjModelList.value, v => {
      return v.id == id;
    });
    if (model) {
      if (model.isCache == 1) {
        commonApi.sp
          .getContentById({
            id: id
          })
          .then(data => {
            model.content = data;
            adaptModelData(model);
            res(model.content);
          });
      } else {
        let list = repeatFn(
          screenObj.screen.value.page[screenObj.selectPage.value.id].components,
          v => {
            if (v.id == refObj.currentNode.value.id) {
              v.sourceData = {
                ...v.sourceData,
                ...model
              };
              sendReqWithoutDeal(
                v,
                () => {
                  res(v.modelResData);
                },
                true
              );
            }
            return v;
          }
        );
        screenObj.screen.value.page[
          screenObj.selectPage.value.id
        ].components = list;
      }
    }
  });
};

const adaptModelData = obj => {
  let content = JSON.parse(obj.content),
    result = null;
  if (obj.isObj == 0) {
    if (_.isArray(content)) {
      result = { ...content[0] };
    } else if (typeof content == "object") {
      result = { ...content };
    }
  } else {
    if (_.isArray(content)) {
      result = [...content];
    } else if (typeof content == "object") {
      result = [];
      result.push(content);
    }
  }

  result = replaceModelEntity(result, obj.entities);
  obj.content = JSON.stringify(result, null, "\t");
};

const replaceModelEntity = (list, entities) => {
  if (_.isArray(list)) {
    list.forEach(v => {
      if (typeof v == "object" && !_.isArray(v)) {
        for (let key in v) {
          entities.forEach(e => {
            if (e.source == key) {
              v[e.name] = v[key];
              if (e.source != e.name) Reflect.deleteProperty(v, key);
              if (typeof v[e.name] == "object") {
                v[e.name] = replaceModelEntity(v[e.name], e.children);
              }
            }
          });
        }
      } else if (_.isArray(v)) {
        v.forEach(o => {
          if (typeof o == "object" && !_.isArray(o)) {
            for (let key2 in o) {
              entities.forEach(e => {
                if (e.source == key2) {
                  o[e.name] = o[key2];
                  if (e.source != e.name) Reflect.deleteProperty(o, key2);
                  if (typeof o[e.name] == "object") {
                    o[e.name] = replaceModelEntity(o[e.name], e.children);
                  }
                }
              });
            }
          }
        });
      } else {
        entities.forEach(e => {
          if (e.source == key) {
            v[e.name] = v[key];
            if (e.source != e.name) Reflect.deleteProperty(v, key);
          }
        });
      }
    });
  } else if (typeof list == "object") {
    for (let key1 in list) {
      entities.forEach(e => {
        if (e.source == key1) {
          list[e.name] = list[key1];
          if (e.source != e.name) Reflect.deleteProperty(list, key1);
          if (typeof list[e.name] == "object") {
            list[e.name] = replaceModelEntity(list[e.name], e.children);
          }
        }
      });
    }
  }
  return list;
};

const adaptOldData = obj => {
  if (obj.id == "m00001") {
    obj.style.width = screenObj.screen.value.screenWidth;
    obj.style.height = screenObj.screen.value.screenHeight;
  }

  if (obj.funcEventStr) obj.funcEvent = JSON.parse(obj.funcEventStr);
  if (obj.basicDataStr) obj.basicConfigData = JSON.parse(obj.basicDataStr);
  if (obj.transDataStr) obj.transformData = JSON.parse(obj.transDataStr);

  if (!obj.animateClass) {
    obj.animateClass = {
      in: {
        show: false,
        delay: 0,
        time: 1000,
        direction: "",
        name: ""
      },
      out: {
        show: false,
        delay: 0,
        time: 1000,
        direction: "Down",
        name: ""
      },
      loop: {
        show: false,
        delay: 0,
        time: 1000,
        name: ""
      },
    };
  }else if(!obj.animateClass.in.loadType) {
    obj.animateClass.in.loadType = 1;
  }

  if(!obj.animateColors) {
    obj.animateColors= [];
  }

  if (!obj.funcEvent.leave) {
    obj.funcEvent.leave = {
      name: "鼠标离开",
      effect: false,
      event: []
    };
  }

  if (obj.type == "custom-calendar1" && !obj.funcEvent.date) {
    obj.funcEvent.date = {
      name: "日历事件",
      effect: false,
      event: []
    };
  }

  if (obj.type == "custom-search1" && !obj.funcEvent.input) {
    obj.funcEvent.input = {
      name: "输入框事件",
      effect: false,
      event: []
    };
  }

  if (!obj.funcEvent.series) {
    obj.funcEvent.series = {
      name: "元素点击事件",
      effect: false,
      event: []
    };
  }

  for (let key in obj.funcEvent) {
    if (
      typeof obj.funcEvent[key].event == "object" &&
      obj.funcEvent[key].event.type
    ) {
      obj.funcEvent[key].event = [obj.funcEvent[key].event];
    } else if (!_.isArray(obj.funcEvent[key].event)) {
      obj.funcEvent[key].event = [];
    }

    obj.funcEvent[key].event.forEach(v => {
      if (v.jumpPage) {
        if (!v.jumpPage.type) v.jumpPage.type = 1;
      } else if (!v.jumpPage) {
        v.jumpPage = {
          type: 1,
          id: ""
        };
      }

      if (v.relyComp && !v.relyComp.name) {
        let list = repeatFn(
          screenObj.screen.value.page[screenObj.selectPage.value.id].components,
          data => {
            if (data.id == v.relyComp.id) {
              v.relyComp.name = data.name;
            }
            return data;
          }
        );
        screenObj.screen.value.page[
          screenObj.selectPage.value.id
        ].components = list;
      }

      if (!v.refreshComp) {
        v.refreshComp = {
          ids: []
          // name: ""
        };
      }

      if (!v.refreshComp.ids) {
        v.refreshComp.ids = [];
        if (v.refreshComp.id) {
          v.refreshComp.ids.push(v.refreshComp.id);
        }
      }

      // if(v.refreshComp && !v.refreshComp.name) {
      //   let list = repeatFn(screenObj.screen.value.page[screenObj.selectPage.value.id].components, (data) => {
      //     let obj = _.find(v.refreshComp.ids, o => {
      //       return o == data.id;
      //     })
      //     if(obj) {
      //       v.refreshComp.name = obj.name;
      //     }
      //     return data;
      //   })
      //   screenObj.screen.value.page[screenObj.selectPage.value.id].components = list;
      // }

      if (v.paramsComp) {
        if (!v.paramsComp.type) v.paramsComp.type = 3;
      } else if (!v.paramsComp) {
        v.paramsComp = {
          type: 3,
          key: ""
        };
      }

      if (v.styleComp) {
        if (!v.styleComp.type) v.styleComp.type = 1;
        if (!v.styleComp.name) {
          let list = repeatFn(
            screenObj.screen.value.page[screenObj.selectPage.value.id]
              .components,
            data => {
              if (data.id == v.relyComp.id) {
                v.styleComp.name = data.name;
              }
              return data;
            }
          );
          screenObj.screen.value.page[
            screenObj.selectPage.value.id
          ].components = list;
        }
      } else if (!v.styleComp) {
        v.styleComp = {
          type: 1,
          id: "",
          value: "",
          name: ""
        };
      }
    });
  }
  if (!obj.eventShowFunc) {
    obj.eventShowFunc = "(data) => {return true;}";
  }
  if (!obj.eventHideFunc) {
    obj.eventHideFunc = "(data) => {return true;}";
  }
  if (!obj.eventShowOrHideFunc) {
    obj.eventShowOrHideFunc = "(data) => {return true;}";
  }
  if (!obj.paramsDealFunc) {
    obj.paramsDealFunc = "(data) => {return {type: 0, params: {}};}";
  }

  if (obj.style.backgroundImage)
    obj.style.tempImage = window.origin + obj.style.backgroundImage;

  if (obj.type == "text" && !obj.style.textIndent) {
    obj.style.textIndent = 0;
  }

  if (obj.type == "progress2" && obj.option) {
    if (!obj.option.data.percent)
      obj.option.data.percent = (
        (obj.option.data.current / obj.option.data.total) *
        100
      ).toFixed(2);
    Reflect.deleteProperty(obj.option.data, "total");
    Reflect.deleteProperty(obj.option.data, "current");
    if (!obj.transformData)
      obj.basicConfigData = obj.resultData = obj.transformData = obj.option;
  }

  if (!obj.style.areaOpacity) obj.style.areaOpacity = 1;

  if (obj.url && obj.url.length == 32)
    obj.fileUrl = window.origin + "/file/" + obj.url;
  else obj.fileUrl = obj.url;

  if (!obj.style.backgroundColor) obj.style.backgroundColor = "";

  if (!obj.style.border)
    obj.style.border = {
      width: 0,
      color: ""
    };

  if (!obj.style.filter) obj.style.filter = 0;

  if (!obj.style.radius) {
    obj.style.radius = {
      width: obj.style.borderTopRadius,
      borderTopRadius: obj.style.borderTopRadius,
      borderLeftRadius: obj.style.borderLeftRadius,
      borderBottomRadius: obj.style.borderBottomRadius,
      borderRightRadius: obj.style.borderRightRadius
    };
    Reflect.deleteProperty(obj.style, "borderTopRadius");
    Reflect.deleteProperty(obj.style, "borderLeftRadius");
    Reflect.deleteProperty(obj.style, "borderBottomRadius");
    Reflect.deleteProperty(obj.style, "borderRightRadius");
  } else {
    if (!obj.style.radius.borderTopRadius)
      obj.style.radius.borderTopRadius = obj.style.radius.width;
    if (!obj.style.radius.borderBottomRadius)
      obj.style.radius.borderBottomRadius = obj.style.radius.width;
    if (!obj.style.radius.borderLeftRadius)
      obj.style.radius.borderLeftRadius = obj.style.radius.width;
    if (!obj.style.radius.borderRightRadius)
      obj.style.radius.borderRightRadius = obj.style.radius.width;
  }

  if (obj.style.border) {
    if (!obj.style.border.topWidth)
      obj.style.border.topWidth = obj.style.border.width;
    if (!obj.style.border.bottomWidth)
      obj.style.border.bottomWidth = obj.style.border.width;
    if (!obj.style.border.leftWidth)
      obj.style.border.leftWidth = obj.style.border.width;
    if (!obj.style.border.rightWidth)
      obj.style.border.rightWidth = obj.style.border.width;
  }

  if (obj.type == "three-map" && !obj.config.pointSpeed) {
    obj.config.pointSpeed = 20;
  }

  if (obj.type == "layout") {
    if(!obj.config) {
      obj.config = {
        body: [],
        header: {},
        isVertical: false,
        rowIndex: 1,
        colIndex: 1,
        line: {
          width: 0,
          height: 0,
          color: ''
        }
      };
    }else if(!obj.config.line){
      obj.config.line = {
        width: 0,
        height: 0,
        color: ''
      }
    }
    
    if(obj.basicConfigData && !obj.basicConfigData.line){
      obj.basicConfigData.line = {
        width: 0,
        height: 0,
        color: ''
      }
    }
  }

  if (obj.sourceData.disableProxy) obj.sourceData.disableProxy = false;

  if (obj.sourceData.requestMethod == "GET|POST")
    obj.sourceData.requestMethod = "GET";

  if (obj.type == "chart") {
    if (!obj.seriesEvent) {
      obj.seriesEvent = {
        disable: false,
        jumpPage: {
          id: ""
        }
      };
    }
  }

  if (obj.type == "canvas-comp") {
    if (!obj.config.color) {
      obj.config.color = "";
    }
  }
};

const createBoard = pageId => {
  let comp = _.find(screenObj.screen.value.page[pageId].components, v => {
    return v.id == "m00001";
  });
  if (comp) {
    return comp;
  }
  let board = {
    id: "m00001",
    disabled: true,
    name: "画板",
    icon: "beicon-mianban",
    type: "canvas",
    active: true,
    lock: true,
    style: {
      width: screenObj.screen.value.screenWidth,
      height: screenObj.screen.value.screenHeight,
      left: 0,
      top: 0,
      zIndex: -9999,
      display: true,
      borderRadius: 0,
      backgroundColor: "#141423",
      backgroundImage: "",
      opacity: 1
    },
    animate: "",
    animateClass: {
      in: {
        show: false,
        delay: 0,
        time: 1,
        direction: "",
        name: ""
      },
      out: {
        show: false,
        delay: 0,
        time: 1,
        direction: "Down",
        name: ""
      },
      loop: {
        show: false,
        delay: 0,
        time: 1,
        name: ""
      }
    },
    sourceData: {
      sourceDataType: 0,
      sourceData: "",
      resultData: "",
      requestUrl: "",
      requestMethod: "GET|POST",
      requestParams: "",
      requestHeaders: "",
      requestBody: "",
      dataRefresh: "0",
      dataHandle: ""
    },
    funcEvent: {
      leftClick: {
        name: "鼠标左键单击",
        effect: false,
        event: {}
      },
      leftMouseDown: {
        name: "鼠标左键按下",
        effect: false,
        event: {}
      },
      leftMouseUp: {
        name: "鼠标左键抬起",
        effect: false,
        event: {}
      },
      leftDoubleClick: {
        name: "鼠标左键双击",
        effect: false,
        event: {}
      },
      hover: {
        name: "鼠标悬停",
        effect: false,
        event: {}
      }
    },
    params: [
      {
        key: "string",
        value: "string"
      }
    ],
    url: ""
  };
  screenObj.screen.value.page[pageId].components.push(board);
  screenObj.screen.value.page[pageId].components = _.sortBy(
    screenObj.screen.value.page[pageId].components,
    v => {
      return v.style.zIndex;
    }
  );
  sortComList(screenObj.screen.value.page[pageId].components);
  return board;
};

const createBackground = (id, list) => {
  let board = {
    id: id,
    name: "底页",
    icon: "beicon-dongtaimianban",
    type: "dynamic-board",
    active: false,
    lock: false,
    scroll: false,
    autoScroll: false,
    style: {
      filter: 0,
      width: screenObj.screen.value.screenWidth,
      height: screenObj.screen.value.screenHeight,
      left: 0,
      top: 0,
      zIndex: 0,
      display: true,
      borderRadius: 0,
      borderTopRadius: 0,
      borderLeftRadius: 0,
      borderBottomRadius: 0,
      borderRightRadius: 0,
      backgroundColor: "",
      backgroundImage: "",
      opacity: 1
    },
    animate: "",
    animateClass: {
      in: {
        show: false,
        delay: 0,
        time: 1,
        direction: "",
        name: ""
      },
      out: {
        show: false,
        delay: 0,
        time: 1,
        direction: "Down",
        name: ""
      },
      loop: {
        show: false,
        delay: 0,
        time: 1,
        name: ""
      }
    },
    sourceData: {
      sourceDataType: 0,
      sourceData: "",
      resultData: "",
      requestUrl: "",
      requestMethod: "GET",
      requestParams: "",
      requestHeaders: "",
      requestBody: "",
      dataRefresh: "0",
      dataHandle: ""
    },
    funcEvent: {
      leftClick: {
        name: "鼠标左键单击",
        effect: false,
        event: []
      },
      leftMouseDown: {
        name: "鼠标左键按下",
        effect: false,
        event: []
      },
      leftMouseUp: {
        name: "鼠标左键抬起",
        effect: false,
        event: []
      },
      leftDoubleClick: {
        name: "鼠标左键双击",
        effect: false,
        event: []
      },
      hover: {
        name: "鼠标悬停",
        effect: false,
        event: []
      },
      leave: {
        name: "鼠标离开",
        effect: false,
        event: []
      }
    },
    params: [
      {
        key: "",
        value: ""
      }
    ],
    url: "",
    children: []
  };
  board.children = list;
  return board;
};

const sortComList = list => {
  list.forEach(v => {
    if (v.children && v.children.length > 0) {
      v.children = _.sortBy(v.children, ch => {
        return ch.style.zIndex;
      });
      sortComList(v.children);
    }
  });
};

const beforeDealInitCompData = obj => {
  if (obj.type == "dynamic-board" || obj.type == "custom-layout1") {
    obj.reChildren = [];
    if (refObj.repeatWidths[obj.id]) {
      obj.style.width = refObj.repeatWidths[obj.id].width;
      obj.style.height = refObj.repeatWidths[obj.id].height;
    }
  } else if (obj.type == "layout") {
    if (refObj.layoutWidths[obj.id]) {
      obj.style.width = refObj.layoutWidths[obj.id];
      obj.repeatLayout = JSON.parse(JSON.stringify(obj.layout));
      if (refObj.recordRows[obj.id])
        obj.layoutDivision = JSON.parse(
          JSON.stringify(refObj.recordRows[obj.id])
        );
    }
  }
};

const dealInitCompData = obj => {
  if (obj.type == "dynamic-board" || obj.type == "custom-layout1") {
    if (obj.type == "custom-layout1") {
      refObj.repeatWidths[obj.id] = {
        width: obj.style.width,
        height: obj.style.height
      };
    }
    refObj.recordDynamic.value[obj.id] = JSON.parse(
      JSON.stringify(obj.children)
    );
    repeatCustomLayout(obj);
  } else if (obj.type == "layout") {
    obj.repeatLayout = [];
    obj.layout.forEach(x => {
      x.forEach(y => {
        adaptOldData(y.comp);
      });
    });
    refObj.recordRows[obj.id] = JSON.parse(
      JSON.stringify(obj.layoutDivision)
    );
    refObj.layoutWidths[obj.id] = obj.style.width;
    obj.repeatLayout = JSON.parse(JSON.stringify(obj.layout));
    repeatLayout(obj);
  }
};

const dealPreCompData = obj => {
  if (obj.type == "dynamic-board" || obj.type == "custom-layout1") {
    refObj.repeatWidths[obj.id] = {
      width: obj.style.width,
      height: obj.style.height
    };
    obj.reChildren = [];
    // repeatCustomLayout(obj);
  } else if (obj.type == "layout") {
    refObj.layoutWidths[obj.id] = obj.style.width;
    obj.layout = JSON.parse(JSON.stringify(obj.repeatLayout));
    refObj.recordRows[obj.id] = JSON.parse(
      JSON.stringify(obj.layoutDivision)
    );
    // repeatLayout(obj);
  }
};

const dealBackCompData = obj => {
  if (obj.type == "custom-layout1") {
    obj.reChildren = JSON.parse(JSON.stringify(obj.children));
    let list = repeatFn(obj.reChildren, v => {
      v.basicConfigData = v.resultData = adaptDataFn(v, v.transformData);
      return v;
    });
    obj.reChildren = list;
    obj.style.width = refObj.repeatWidths[obj.id].width;
    obj.style.height = refObj.repeatWidths[obj.id].height;
  } else if (obj.type == "layout") {
    obj.style.width = refObj.layoutWidths[obj.id];
    obj.repeatLayout = JSON.parse(JSON.stringify(obj.layout));
    obj.layoutDivision = JSON.parse(
      JSON.stringify(refObj.recordRows[obj.id])
    );
  }
};

const useAdaptDataFn = (v, data, prjModelList) => {
  let fn = "";
  switch (v.sourceData.sourceDataType) {
    case 0:
      fn = v.dataFunc;
      break;
    case 1:
      fn = v.apiDataFunc;
      if (v.apiResData) data = JSON.parse(v.apiResData);
      break;
    case 2:
      fn = v.sqlDataFunc;
      if (v.sqlResData) data = JSON.parse(v.sqlResData);
      break;
    case 3:
      fn = v.modelDataFunc;
      if (v.modelResData) data = JSON.parse(v.modelResData);
      else {
        let obj = _.find(prjModelList, e => {
          return e.id == v.sourceData.prjModelId;
        });
        if (obj) data = JSON.parse(obj.content);
      }
      break;
    default:
      break;
  }
  return { data, fn };
};

const adaptParamData = (data, params, others) => {
  if (typeof data == "string") {
    //匹配@() 参数
    let matches = data.match(/\@\((.+?)\)/gi);
    //匹配到
    if (matches) {
      let obj = {};
      matches.forEach(p => {
        //获取key
        let key = p.replace("@(", "");
        key = key.replace(")", "");

        //全局参数
        let gValue = _.find(storeGlobal, cp => {
          return cp.key == key;
        });
        if (gValue) {
          if (gValue.value == "true" || gValue.value == "false")
            gValue.value = !!gValue.value;
          obj[p] = gValue.value;
        }

        //页面参数
        let pageParams = _.find(
          screenObj.screen.value.page[screenObj.selectPage.value.id].components,
          v => {
            return v.id == "m00001";
          }
        );

        if (pageParams) {
          let pValue = _.find(pageParams.params, cp => {
            return cp.key == key;
          });
          if (pValue) {
            if (pValue.value == "true" || pValue.value == "false")
              pValue.value = !!pValue.value;
            obj[p] = pValue.value;
          }
        }

        //组件参数
        let value = _.find(params, cp => {
          return cp.key == key;
        });
        if (value) {
          if (value.value == "true" || value.value == "false")
            value.value = !!value.value;
          obj[p] = value.value;
        }

        if (key.indexOf("jumpParams") != -1) {
          let args = key.split(".");
          let args2 = JSON.parse(JSON.stringify(args));
          args2.splice(0, 1);
          if(refObj.jumpParams) {
            let seriesValue = !refObj.jumpParams[args[1]]
            ? ""
            : getDeepPropertyVlaue(args2, refObj.jumpParams);
          obj[p] = seriesValue;
          }
        }

        if (key.indexOf("parentParams") != -1 && others) {
          let pArgs = key.split(".");
          let layoutValue = null;
          let reArgs = [];
          for (let i = 2; i < pArgs.length; i++) {
            reArgs.push(pArgs[i]);
          }
          if (pArgs[1] == "header") {
            let args2 = JSON.parse(JSON.stringify(pArgs));
            args2.splice(0, 1);
            layoutValue = !others[pArgs[1]]
              ? ""
              : getDeepPropertyVlaue(args2, others);
          } else if (pArgs[1] == "body") {
            if (!others.isVertical && (others.x == 0 || others.x)) {
              layoutValue = !others[pArgs[1]][others.x]
                ? ""
                : getDeepPropertyVlaue(reArgs, others[pArgs[1]][others.x]);
            } else if (others.y == 0 || others.y) {
              layoutValue = !others[pArgs[1]][others.y]
                ? ""
                : getDeepPropertyVlaue(reArgs, others[pArgs[1]][others.y]);
            }
          } else if (pArgs[1] == "children") {
            if (others.index == 0 || others.index) {
              layoutValue = !(others[pArgs[1]] ? others[pArgs[1]][others.index] : '')
                ? ""
                : getDeepPropertyVlaue(reArgs, others[pArgs[1]][others.index]);
            }
          } else if (pArgs[1] == "parentParams") {
            let parentList = _.filter(pArgs, v => {
              return v == "parentParams";
            });
            let args2 = [];
            for (let i = parentList.length; i < pArgs.length; i++) {
              args2.push(pArgs[i]);
            }
            layoutValue = iteratorParams(args2, others, parentList.length);
          } else if (others.data) {
            let args2 = JSON.parse(JSON.stringify(pArgs));
            args2.splice(0, 1);
            layoutValue = !others.data[pArgs[1]]
              ? ""
              : getDeepPropertyVlaue(args2, others.data);
          }
          obj[p] = layoutValue;
        }
      });
      //替换数据
      for (let key in obj) {
        if (data.indexOf(`"${key}"`) != -1 && typeof obj[key] == "boolean")
          data = data.replaceAll(`"${key}"`, obj[key]);

        if (obj[key] && typeof obj[key] == "object") {
          obj[key] = dealObjData(obj[key]);
          data = data.replaceAll(`"${key}"`, obj[key]);
        } else {
          data = data.replaceAll(key, obj[key]);
        }
      }
    }
  }

  return data;
};

const iteratorParams = (args, others, count) => {
  let layoutValue = null;
  let params = null;
  if (count > 1) {
    count--;
    repeatFn(
      screenObj.screen.value.page[screenObj.selectPage.value.id].components,
      v => {
        if (v.id == others.pid) {
          if (v.parent && refObj.parentParams.value[v.parent.pid]) {
            params = refObj.parentParams.value[v.parent.pid];
            if (
              !refObj.parentParams.value[v.parent.pid].isVertical &&
              (v.parent.x == 0 || v.parent.x)
            )
              params.x =
                v.parent.x -
                  (refObj.parentParams.value[v.parent.pid]
                    ? refObj.parentParams.value[v.parent.pid].rowIndex
                    : 0) <
                0
                  ? 0
                  : v.parent.x -
                    refObj.parentParams.value[v.parent.pid].rowIndex;
            else if (v.parent.y == 0 || v.parent.y)
              params.y =
                v.parent.y -
                  (refObj.parentParams.value[v.parent.pid]
                    ? refObj.parentParams.value[v.parent.pid].colIndex
                    : 0) <
                0
                  ? 0
                  : v.parent.y -
                    refObj.parentParams.value[v.parent.pid].colIndex;
            else if (v.parent.index == 0 || v.parent.index)
              params.index = v.parent.index;
          } else {
            params = refObj.parentParams.value[others.pid];
          }
        }
        return v;
      }
    );
  }

  if (count == 1 && params) {
    if (args[0] == "header") {
      layoutValue = !params[args[0]] ? "" : getDeepPropertyVlaue(args, params);
    } else if (args[0] == "body") {
      if (!params.isVertical && (params.x == 0 || params.x)) {
        layoutValue = !params[args[0]][params.x]
          ? ""
          : getDeepPropertyVlaue(args, params[args[0]][params.x]);
      } else if (params.y == 0 || params.y) {
        layoutValue = !params[args[0]][params.y]
          ? ""
          : getDeepPropertyVlaue(args, params[args[0]][params.y]);
      }
    } else if (args[0] == "children") {
      if (params.index == 0 || params.index) {
        layoutValue = !(params[args[0]]? params[args[0]][params.index]: '')
          ? ""
          : getDeepPropertyVlaue(args, params[args[0]][params.index]);
      }
    } else if (params.data) {
      layoutValue = !params.data[args[0]]
        ? ""
        : getDeepPropertyVlaue(args, params.data);
    }
  }

  if (count > 1) layoutValue = iteratorParams(args, params, count);

  return layoutValue;
};

const dealObjData = obj => {
  let res = "";
  if (typeof obj == "object") {
    if (_.isArray(obj)) {
      res = "[";
      for (let i = 0; i < obj.length; i++) {
        if (typeof obj[i] == "object") {
          res = res + dealObjData(obj[i]);
        } else {
          res = res + `"` + obj[i] + `"`;
        }
        res = res + ",";
      }
      if (obj.length > 0) {
        res = res.substring(0, res.length - 1);
      }
      res = res + "]";
    } else {
      res = "{";
      for (let key in obj) {
        if (typeof obj[key] == "object") {
          res = res + dealObjData(obj[key]);
        } else {
          res = res + `"` + obj[key] + `"`;
        }
        res = res + ",";
      }
      if (obj) {
        res = res.substring(0, res.length - 1);
      }
      res = res + "}";
    }
  } else {
    res = obj;
  }

  return res;
};

const getDeepPropertyVlaue = (keys, obj) => {
  let value = "";
  let lastKey = keys[keys.length - 1];
  // keys.splice(0, 1);
  keys.reduce((pre, item) => {
    if (item == lastKey) value = pre[item];
    return pre[item];
  }, obj);
  return value;
};

//组件初始化方法
const compLifeCycle = id => {
  let list = repeatFn(
    screenObj.screen.value.page[screenObj.selectPage.value.id].components,
    v => {
      if (v.id == id) {
        actionByType(v, () => {
          if (v.config && _.contains(staticObj.unContainKeys.value, v.type)) {
            saveDataV([v.id], v.basicConfigData);
          } else if (v.type == "three-map") {
            changeConfig(v.basicConfigData);
          } else if (v.type == "dynamic-board" || v.type == "custom-layout1") {
            v.children.forEach(ch => {
              actionByType(ch, () => {
                if (
                  ch.config &&
                  _.contains(staticObj.unContainKeys.value, ch.type)
                ) {
                  saveDataV([ch.id], ch.basicConfigData);
                } else if (ch.type == "three-map") {
                  changeConfig(ch.basicConfigData);
                }
              });
            });
          }
        });
      }
      return v;
    }
  );
  screenObj.screen.value.page[screenObj.selectPage.value.id].components = list;
};

const actionByType = (obj, callback, fresh = false) => {
  switch (obj.sourceData.sourceDataType) {
    case 1:
      actionInterface(obj, callback, fresh);
      break;
    case 2:
      actionSQL(obj, callback, fresh);
      break;
    case 3:
      actionModel(obj, callback, fresh);
      break;
    default:
      getPageParams(obj, fresh);
      if (callback) callback();
      aysncModelData(obj);
      break;
  }
  
};

//api逻辑有问题，需要重构
const actionInterface = (comp, callback, fresh) => {
  let header = constructData(JSON.parse(comp.sourceData.requestHeaders)),
    params = constructData(JSON.parse(comp.sourceData.requestParams));

  let preFn = eval(replaceParamValue(comp, comp.sourceData.preDealFn)),
    dealFn = eval(replaceParamValue(comp, comp.sourceData.dealFn));
  let result = preFn(header, params);
  (header = result.header), (params = result.params);

  if (comp.sourceData.requestMethod === "GET") {
    let url = comp.sourceData.requestUrl;
    for (let key in params) {
      if (url.indexOf("?") != -1) {
        url = url + "&" + key + "=" + params[key];
      } else {
        url = url + "?" + key + "=" + params[key];
      }
    }
    commonAxios.get(url, header).then(data => {
      comp.apiResData = JSON.stringify(data.data, null, "\t");
      let result = dealFn(data.data, comp.style);
      dealReqData(result, comp);
      getPageParams(comp, fresh);
      if (callback) callback();
    });
  } else if (comp.sourceData.requestMethod === "POST") {
    commonAxios.post(comp.sourceData.requestUrl, params, header).then(data => {
      comp.apiResData = JSON.stringify(data.data, null, "\t");
      let result = dealFn(data.data, comp.style);
      dealReqData(result, comp);
      getPageParams(comp, fresh);
      if (callback) callback();
    });
  } else {
    commonApi.sp
      .httpClientInterface({
        type: comp.sourceData.requestMethod,
        url: comp.sourceData.requestUrl,
        params: JSON.stringify(params),
        header: JSON.stringify(header)
      })
      .then(data => {
        let res = JSON.parse(data.data);
        comp.apiResData = JSON.stringify(res, null, "\t");
        let result = dealFn(data.data, comp.style);

        dealReqData(result, comp);
        getPageParams(comp, fresh);
        if (callback) callback();
      });
  }
};

const actionSQL = (comp, callback, fresh) => {
  commonApi.sp
    .getDBChartData({
      databaseSourceId: comp.sourceData.databaseSourceId,
      sql: comp.sourceData.sql
    })
    .then(data => {
      comp.sqlResData = JSON.stringify(data, null, "\t");
      let result = dealSqlData(comp);
      dealReqData(result, comp);
      getPageParams(comp);
      if (callback) callback();
    });
};

const dealSqlData = obj => {
  let data = JSON.parse(obj.sqlResData);
  let dataFunc = eval(replaceParamValue(obj, obj.sqlDataFunc));
  return dataFunc(data, comp.style);
};

const sendRequest = (obj, callback, fresh) => {
  refObj.loadCount++;
  let header = constructData(JSON.parse(obj.sourceData.header)),
    params = constructData(JSON.parse(obj.sourceData.params));

  let newUrl = adaptParams(obj.sourceData.url);
  let pFn = adaptParams(obj.sourceData.preDealFn);
  let dFn = adaptParams(obj.sourceData.dealFn);
  let preFn = eval(pFn),
    dealFn = eval(dFn);
  let result = preFn(header, params);
  (header = result.header), (params = result.params);

  if (obj.sourceData.actionType == 1) {
    let pContent = adaptParams(obj.sourceData.paramContent),
      pDefault = adaptParams(obj.sourceData.paramDefault);

    let cont, def;
    try {
      if (pContent) cont = JSON.parse(pContent);
      if (pDefault) def = JSON.parse(pDefault);
    } catch (error) {
      if (pContent) {
        cont = eval(`
        let cont=${pContent};
        cont;
        `);
      }

      if (pDefault) {
        def = eval(`
        let def=${pDefault};
        def;
        `);
      }
    }

    let pcObj = dealParamContent(cont, def);
    if (pcObj) params = pcObj;
  }

  if (obj.sourceData.proxy == 0) {
    if (obj.sourceData.actionType == 0) {
      let url = newUrl;
      for (let key in params) {
        if (url.indexOf("?") != -1) {
          url = url + "&" + key + "=" + params[key];
        } else {
          url = url + "?" + key + "=" + params[key];
        }
      }

      commonAxios.get(url, header).then(data => {
        obj.sourceData.content = obj.sourceData.sourceData = JSON.stringify(data.data, null, "\t");
        let res = dealFn(data.data);
        obj.modelResData = JSON.stringify(res, null, "\t");
        let dataFunc = eval(replaceParamValue(obj, obj.modelDataFunc));
        if(typeof res == 'string')
          res = JSON.parse(res);
        let result = dataFunc(res, obj.style);
        dealReqData(result, obj);
        getPageParams(obj, fresh);

        if (callback) callback();
        aysncModelData(obj);
        refObj.loadCount--;
      }).catch(e => {
        message.error("接口异常");
        refObj.loadCount--;
      });
    } else if (obj.sourceData.actionType == 1) {
      commonAxios.post(newUrl, params, header).then(data => {
        obj.sourceData.content = obj.sourceData.sourceData = JSON.stringify(data.data, null, "\t");
        let res = dealFn(data.data);
        obj.modelResData = JSON.stringify(res, null, "\t");
        if(typeof res == 'string')
          res = JSON.parse(res);
        let dataFunc = eval(replaceParamValue(obj, obj.modelDataFunc));
        let result = dataFunc(res, obj.style);
        dealReqData(result, obj);
        getPageParams(obj, fresh);

        if (callback) callback();
        aysncModelData(obj);
        refObj.loadCount--;
      }).catch(e => {
        message.error("接口异常");
        refObj.loadCount--;
      });;
    }
  } else {
    commonApi.sp
      .httpClientInterface({
        type: obj.sourceData.actionType == 0 ? "GET" : "POST",
        url: newUrl,
        params: JSON.stringify(params),
        header: JSON.stringify(header)
      })
      .then(data => {
        data = JSON.parse(data);
        obj.sourceData.content = obj.sourceData.sourceData = JSON.stringify(data, null, "\t");
        let res = dealFn(data);
        obj.modelResData = JSON.stringify(res, null, "\t");
        if(typeof res == 'string')
          res = JSON.parse(res);
        let dataFunc = eval(replaceParamValue(obj, obj.modelDataFunc));
        let result = dataFunc(res, obj.style);
        dealReqData(result, obj);
        getPageParams(obj, fresh);
        
        if (callback) callback();
        aysncModelData(obj);
        refObj.loadCount--;
      }).catch(e => {
        message.error("接口异常");
        refObj.loadCount--;
      });;
  }
};

const aysncModelData = (obj) => {
  let list = repeatFn(screenObj.screen.value.page[screenObj.selectPage.value.id].components, v => {
    if(v.id == obj.id) {
      v = {...v, ...obj};
    }
    return v;
  })
  screenObj.screen.value.page[screenObj.selectPage.value.id].components = list;
};

const sendReqWithoutDeal = (obj, callback, fresh) => {
  let header = constructData(JSON.parse(obj.sourceData.header)),
    params = constructData(JSON.parse(obj.sourceData.params));

  let newUrl = adaptParams(obj.sourceData.url);
  let pFn = adaptParams(obj.sourceData.preDealFn);
  let dFn = adaptParams(obj.sourceData.dealFn);
  let preFn = eval(pFn),
    dealFn = eval(dFn);
  let result = preFn(header, params);
  (header = result.header), (params = result.params);

  if (obj.sourceData.actionType == 1) {
    let pContent = adaptParams(obj.sourceData.paramContent),
      pDefault = adaptParams(obj.sourceData.paramDefault);

    let cont, def;
    try {
      if (pContent) cont = JSON.parse(pContent);
      if (pDefault) def = JSON.parse(pDefault);
    } catch (error) {
      if (pContent) {
        cont = eval(`
        let cont=${pContent};
        cont;
        `);
      }

      if (pDefault) {
        def = eval(`
        let def=${pDefault};
        def;
        `);
      }
    }

    let pcObj = dealParamContent(cont, def);
    if (pcObj) params = pcObj;
  }

  if (obj.sourceData.proxy == 0) {
    if (obj.sourceData.actionType == 0) {
      let url = newUrl;
      for (let key in params) {
        if (url.indexOf("?") != -1) {
          url = url + "&" + key + "=" + params[key];
        } else {
          url = url + "?" + key + "=" + params[key];
        }
      }

      commonAxios.get(url, header).then(data => {
        obj.sourceData.sourceData = JSON.stringify(data.data, null, "\t");
        let res = dealFn(data.data);
        obj.modelResData = JSON.stringify(res, null, "\t");
        if (callback) callback();
      });
    } else if (obj.sourceData.actionType == 1) {
      commonAxios.post(newUrl, params, header).then(data => {
        obj.sourceData.sourceData = JSON.stringify(data.data, null, "\t");
        let res = dealFn(data.data);
        obj.modelResData = JSON.stringify(res, null, "\t");
        if (callback) callback();   
      });
    }
  } else {
    commonApi.sp
      .httpClientInterface({
        type: obj.sourceData.actionType == 0 ? "GET" : "POST",
        url: newUrl,
        params: JSON.stringify(params),
        header: JSON.stringify(header)
      })
      .then(data => {
        data = JSON.parse(data);
        obj.sourceData.sourceData = JSON.stringify(data, null, "\t");
        let res = dealFn(data);
        obj.modelResData = JSON.stringify(res, null, "\t");
        if (callback) callback();
      });
  }
};

const adaptParams = data => {
  //匹配@() 参数
  let matches = data.match(/\@\((.+?)\)/gi);
  //匹配到
  if (matches) {
    let obj = {};
    matches.forEach(p => {
      //获取key
      let key = p.replace("@(", "");
      key = key.replace(")", "");
      let args = key.split(".");

      //全局参数
      if (args[0] == "global") {
        let gValue = _.find(storeGlobal, cp => {
          return cp.key == args[1];
        });
        if (gValue) {
          if (gValue.value == "true" || gValue.value == "false")
            gValue.value = !!gValue.value;
          obj[p] = gValue.value;
        }
      }

      if (args[0] == "page") {
        for (let sheet in storePage) {
          if (args[1] == sheet) {
            let pValue = _.find(storePage[sheet], cp => {
              return cp.key == args[2];
            });
            if (pValue) {
              if (pValue.value == "true" || pValue.value == "false")
                pValue.value = !!pValue.value;
              obj[p] = pValue.value;
            }
          }
        }
      }

      let curPage = _.find(
        screenObj.screen.value.page[screenObj.selectPage.value.id].components,
        v => {
          return v.id == "m00001";
        }
      );

      if (args[0] == "currentPage" && curPage) {
        let pValue = _.find(curPage.params, cp => {
          return cp.key == args[1];
        });
        if (pValue) {
          if (pValue.value == "true" || pValue.value == "false")
            pValue.value = !!pValue.value;
          obj[p] = pValue.value;
        }
      }
    });

    //替换数据
    for (let key in obj) {
      if (data.indexOf(`"${key}"`) != -1 && typeof obj[key] == "boolean")
        data = data.replaceAll(`"${key}"`, obj[key]);

      if (obj[key] && typeof obj[key] == "object") {
        obj[key] = dealObjData(obj[key]);
        data = data.replaceAll(`"${key}"`, obj[key]);
      } else {
        data = data.replaceAll(key, obj[key]);
      }
    }
  }
  return data;
};

const dealParamContent = (data, def) => {
  let result = {};
  if (def) {
    for (let key in def) {
      result[key] = def[key];
    }

    for (let key2 in data) {
      if (!data[key2] && def[key2]) result[key2] = def[key2];
      else result[key2] = data[key2];
    }
  } else {
    result = data;
  }

  return result;
};

const actionModel = (obj, callback, fresh) => {
  let model = _.find(screenObj.prjModelList.value, v => {
    return v.id == obj.sourceData.prjModelId;
  });
  if (model) {
    if (model.isCache) {
      obj.modelResData = model.content;
      let dataFunc = eval(replaceParamValue(obj, obj.modelDataFunc));
      let result = dataFunc(JSON.parse(obj.modelResData), obj.style);
      dealReqData(result, obj);
      getPageParams(obj, fresh);

      if (callback) callback();
    } else {
      obj.sourceData = {
        ...obj.sourceData,
        ...model
      };
      sendRequest(obj, callback, fresh);
    }
  }
};

const constructData = data => {
  let result = {};
  data.forEach(v => {
    let pKey = adaptParams(v.key);
    let pValue = adaptParams(v.value);
    let pDefault = adaptParams(v.default);
    if (pKey) result[pKey] = !pValue ? pDefault : pValue;
  });

  return result;
};

const dealReqData = (result, obj) => {
  if (result.style) {
    if (obj.config) {
      obj.config = result.data;
    } else if (obj.type == "chart") {
      obj.option = result.data;
    } else {
      obj.sourceData.sourceData = result.data;
    }
    obj.transformData = result.data;
    obj.style = result.style;
  } else {
    if (obj.config) {
      obj.config = result;
    } else if (obj.type == "chart") {
      obj.option = result;
    } else {
      obj.sourceData.sourceData = result;
    }
    obj.transformData = result;
  }

  obj.jsonOption = JSON.stringify(obj.transformData, null, '\t');
};

const getPageParams = (v, fresh) => {
  if (typeof v.transformData == "number" || v.transformData) {
    let data = JSON.stringify(v.transformData);

    //匹配@() 参数
    let matches = data.match(/\@\((.+?)\)/gi);
    //匹配到
    if (matches) {
      let obj = {};
      matches.forEach(p => {
        //获取key
        let key = p.replace("@(", "");
        key = key.replace(")", "");

        //全局参数
        let gValue = _.find(storeGlobal, cp => {
          return cp.key == key;
        });
        if (gValue) {
          if (gValue.value == "true" || gValue.value == "false")
            gValue.value = !!gValue.value;
          obj[p] = gValue.value;
        }

        //页面参数
        let pageParams = _.find(
          screenObj.screen.value.page[screenObj.selectPage.value.id].components,
          v => {
            return v.id == "m00001";
          }
        );

        if (pageParams) {
          let pValue = _.find(pageParams.params, cp => {
            return cp.key == key;
          });
          if (pValue) {
            if (pValue.value == "true" || pValue.value == "false")
              pValue.value = !!pValue.value;
            obj[p] = pValue.value;
          }
        }

        //组件参数
        let value = _.find(v.params, cp => {
          return cp.key == key;
        });
        if (value) {
          if (value.value == "true" || value.value == "false")
            value.value = !!value.value;
          obj[p] = value.value;
        }

        if (key.indexOf("jumpParams") != "-1") {
          let args = key.split(".");
          let args2 = JSON.parse(JSON.stringify(args));
          args2.splice(0, 1);
          if(refObj.jumpParams) {
            let seriesValue = !refObj.jumpParams[args[1]]
            ? ""
            : getDeepPropertyVlaue(args2, refObj.jumpParams);
          obj[p] = seriesValue;
          }
        }

        if (key.indexOf("parentParams") != -1 && v.parent) {
          let args = key.split(".");
          let layoutValue = null;
          let reArgs = [];
          for (let i = 2; i < args.length; i++) {
            reArgs.push(args[i]);
          }
          if (args[1] == "header") {
            let args2 = JSON.parse(JSON.stringify(args));
            args2.splice(0, 1);
            layoutValue = !refObj.parentParams.value[v.parent.pid][args[1]]
              ? ""
              : getDeepPropertyVlaue(
                  args2,
                  refObj.parentParams.value[v.parent.pid]
                );
          } else if (args[1] == "body") {
            if (
              !refObj.parentParams.value[v.parent.pid].isVertical &&
              (v.parent.x || v.parent.x == 0)
            ) {
              layoutValue = !refObj.parentParams.value[v.parent.pid][args[1]][v.parent.x - refObj.parentParams.value[v.parent.pid].rowIndex]
                ? ""
                : getDeepPropertyVlaue(
                    reArgs,
                    refObj.parentParams.value[v.parent.pid][args[1]][v.parent.x - refObj.parentParams.value[v.parent.pid].rowIndex]
                  );
            } else if (v.parent.y || v.parent.y == 0) {
              layoutValue = !refObj.parentParams.value[v.parent.pid][args[1]][
                v.parent.y - refObj.parentParams.value[v.parent.pid].colIndex
              ]
                ? ""
                : getDeepPropertyVlaue(
                    reArgs,
                    refObj.parentParams.value[v.parent.pid][args[1]][v.parent.y - refObj.parentParams.value[v.parent.pid].colIndex]
                  );
            }
          } else if (args[1] == "children") {
            if (v.parent.index == 0 || v.parent.index) {
              layoutValue = !(refObj.parentParams.value[v.parent.pid]? refObj.parentParams.value[v.parent.pid][args[1]][
                v.parent.index
              ] : '')
                ? ""
                : getDeepPropertyVlaue(
                    reArgs,
                    refObj.parentParams.value[v.parent.pid][args[1]][
                      v.parent.index
                    ]
                  );
            }
          } else if (args[1] == "parentParams") {
            let parentList = _.filter(args, v => {
              return v == "parentParams";
            });
            let args2 = [];
            for (let i = parentList.length; i < args.length; i++) {
              args2.push(args[i]);
            }
            layoutValue = iteratorParams(
              args2,
              refObj.parentParams.value[v.parent.pid],
              parentList.length
            );
          } else if (refObj.parentParams.value[v.parent.pid].data) {
            let args2 = JSON.parse(JSON.stringify(args));
            args2.splice(0, 1);
            layoutValue = !refObj.parentParams.value[v.parent.pid].data[args[1]]
              ? ""
              : getDeepPropertyVlaue(
                  args2,
                  refObj.parentParams.value[v.parent.pid].data
                );
          }
          obj[p] = layoutValue;
        }
      });
      //替换数据
      for (let key in obj) {
        // if(typeof obj[key] == 'object') {
        //   obj[key] = JSON.stringify(obj[key]);
        // }
        if (data.indexOf(`"${key}"`) != -1 && typeof obj[key] == "boolean")
          data = data.replaceAll(`"${key}"`, obj[key]);

        if (obj[key] && typeof obj[key] == "object") {
          obj[key] = dealObjData(obj[key]);
          data = data.replaceAll(`"${key}"`, obj[key]);
        } else {
          data = data.replaceAll(key, obj[key]);
        }
      }
    }

    v.resultData = JSON.parse(data);
    v.resultData = adaptDataFn(v, v.resultData);
    if (
      v.type == "chart" ||
      (v.config && (_.contains(staticObj.unContainKeys.value, v.type) || _.contains(staticObj.otherConfigKey.value, v.type)))
    ) {
      if (!v.basicConfigData) v.basicConfigData = v.resultData;
      let result = unionIDataAndCData(v.resultData, v.basicConfigData);
      v.basicConfigData = result;
    }
    if (!fresh) {
      beforeDealInitCompData(v);
      dealInitCompData(v);
      if (v.type == "chart") refreshComp(v);
    }

    let list = repeatFn(
      screenObj.screen.value.page[screenObj.selectPage.value.id].components,
      data => {
        if (data.id == v.id) {
          data = { ...data, ...v };
        }
        return data;
      }
    );
    screenObj.screen.value.page[
      screenObj.selectPage.value.id
    ].components = list;
  }
};

const refreshComp = v => {
  // if (refObj.stage.value) refObj.stage.value.chartInit(v.id, v.basicConfigData);
  if (refObj.scene.value) refObj.scene.value.chartInit(v.id, v.basicConfigData);
  if (refObj.oview.value) refObj.oview.value.refreshComp([v.id]);
  if (refObj.oviewRe.value) refObj.oviewRe.value.refreshComp([v.id]);
};

const adaptDataFn = (v, data1) => {
  let dealData = replaceParamValue(v, data1);
  let { data, fn } = useAdaptDataFn(v, dealData, screenObj.prjModelList.value);
  fn = replaceParamValue(v, fn);
  let dataFn = eval(fn);
  let result = "";
  try {
    result = dataFn(data, v.style);
  } catch (error) {
    result = data;
  }
  if (result.style) {
    v.style = result.style;
    return result.data;
  }
  return result;
};

//列表组件循环
const repeatLayout = obj => {
  if (refObj.recordRows[obj.id]) {
    obj.layoutDivision = JSON.parse(
      JSON.stringify(refObj.recordRows[obj.id])
    );
    let result = adaptDataFn(obj, obj.basicConfigData);
    refObj.parentParams.value[obj.id] = result;
    obj.repeatLayout = [];
    let repeatCount = result.body.length == 0 ? 0 : result.body.length;
    for (let z = 0; z < obj.layout.length; z++) {
      obj.repeatLayout.push(JSON.parse(JSON.stringify(obj.layout[z])));
    }
    refObj.layoutWidths[obj.id] = JSON.parse(
      JSON.stringify(obj.style.width)
    );

    adpatLayoutData(obj);

    //水平循环
    if (!obj.basicConfigData.isVertical) {
      let list = [],
        headers = [];
      for (let i = 0; i < obj.basicConfigData.rowIndex; i++) {
        headers.push(JSON.parse(JSON.stringify(obj.repeatLayout[i])));
        headers.forEach(v => {
          v.forEach(e => {
            e.comp.id = e.comp.id + parseInt(Math.random() * 1000);
            if (e.comp.parent) e.comp.parent.pid = obj.id;
            if (e.comp.transformData != undefined) {
              e.comp.resultData = adaptDataFn(e.comp, e.comp.transformData);
              e.comp.basicConfigData = e.comp.resultData;
              e.comp.jsonOption = JSON.stringify(
                e.comp.transformData,
                null,
                "\t"
              );
              e.comp.jsonCompJumpOption = getJumpCompParams(e.comp);
            }
          });
        });
      }

      for (
        let i = obj.basicConfigData.rowIndex;
        i <= obj.repeatLayout.length - 1;
        i++
      ) {
        list.push(JSON.parse(JSON.stringify(obj.repeatLayout[i])));
        list.forEach(v => {
          v.forEach(e => {
            e.comp.id = e.comp.id + parseInt(Math.random() * 1000);
            if (e.comp.parent) e.comp.parent.pid = obj.id;
            if (e.comp.transformData != undefined) {
              e.comp.resultData = adaptDataFn(e.comp, e.comp.transformData);
              e.comp.basicConfigData = e.comp.resultData;
              e.comp.jsonOption = JSON.stringify(
                e.comp.transformData,
                null,
                "\t"
              );
              e.comp.jsonCompJumpOption = getJumpCompParams(e.comp);
            }
          });
        });
      }

      obj.repeatLayout = headers;
      let reList = JSON.parse(JSON.stringify(list));
      let rowList = [];
      for (let j = 1; j <= repeatCount; j++) {
        reList.forEach(v => {
          rowList.push(obj.layoutDivision.rows[obj.basicConfigData.rowIndex]);
          for (let z = 0; z < v.length; z++) {
            let e = v[z];
            if (z == 0 && obj.basicConfigData.isSort) {
              e.comp.transformData = e.comp.basicConfigData = e.comp.resultData =
                j + 2;
              e.comp.rowspan = reList.length;
            }
            e.comp.id = "m" + parseInt(Math.random() * 10000000);
            if (e.comp.parent) {
              e.comp.parent.pid = obj.id;
              e.comp.parent.x = obj.basicConfigData.rowIndex + (j - 1);
            }

            if (e.comp.transformData != undefined) {
              e.comp.resultData = adaptDataFn(e.comp, e.comp.transformData);
              e.comp.basicConfigData = e.comp.resultData;
              e.comp.jsonOption = JSON.stringify(
                e.comp.transformData,
                null,
                "\t"
              );
              e.comp.jsonCompJumpOption = getJumpCompParams(e.comp);
            }
          }
        });
        let record = JSON.parse(JSON.stringify(reList));
        obj.repeatLayout = [...obj.repeatLayout, ...record];
      }

      obj.layoutDivision.rows = [...obj.layoutDivision.rows, ...rowList];
    } else {
      //垂直循环
      let list = [],
        headers = [],
        layoutColList = [],
        bodyCol = 0,
        bodyCols = [];
      for (let i = 0; i < obj.basicConfigData.colIndex; i++) {
        layoutColList.push(obj.layoutDivision.cols[i]);
        for (let j = 0; j < obj.repeatLayout.length; j++) {
          if (!headers[j]) headers[j] = [];

          headers[j].push(JSON.parse(JSON.stringify(obj.repeatLayout[j][i])));
        }
        headers.forEach(v => {
          v.forEach(e => {
            e.comp.id = e.comp.id + parseInt(Math.random() * 1000);
            if (e.comp.parent) e.comp.parent.pid = obj.id;
            if (e.comp.transformData != undefined) {
              e.comp.resultData = adaptDataFn(e.comp, e.comp.transformData);
              e.comp.basicConfigData = e.comp.resultData;
              e.comp.jsonOption = JSON.stringify(
                e.comp.transformData,
                null,
                "\t"
              );
              e.comp.jsonCompJumpOption = getJumpCompParams(e.comp);
            }
          });
        });
      }

      if(obj.repeatLayout.length > 0) {
        for (
          let i = obj.basicConfigData.colIndex;
          i <= obj.repeatLayout[0].length - 1;
          i++
        ) {
          for (let j = 0; j < obj.repeatLayout.length; j++) {
            if (!list[j]) list[j] = [];
  
            list[j].push(JSON.parse(JSON.stringify(obj.repeatLayout[j][i])));
          }
  
          list.forEach(v => {
            v.forEach(e => {
              e.comp.id = e.comp.id + parseInt(Math.random() * 1000);
              if (e.comp.parent) e.comp.parent.pid = obj.id;
              if (e.comp.transformData != undefined) {
                e.comp.resultData = adaptDataFn(e.comp, e.comp.transformData);
                e.comp.basicConfigData = e.comp.resultData;
                e.comp.jsonOption = JSON.stringify(
                  e.comp.transformData,
                  null,
                  "\t"
                );
                e.comp.jsonCompJumpOption = getJumpCompParams(e.comp);
              }
            });
          });
        }

        for (let b = 0; b < list[0].length; b++) {
          if (list[0][b]) {
            bodyCol += obj.layoutDivision.cols[b];
            for (let c = 0; c < list[0][b].comp.colspan; c++) {
              bodyCols.push(
                parseInt(
                  obj.layoutDivision.cols[b] / list[0][b].comp.colspan
                )
              );
            }
          }
        }
      }
      
      obj.repeatLayout = headers;
      let reList = JSON.parse(JSON.stringify(list));
      let colCompList = [];

      obj.style.width -= bodyCol;

      for (let j = 1; j <= repeatCount; j++) {
        obj.style.width += bodyCol;
        layoutColList = [...layoutColList, ...bodyCols];
        for (let m = 0; m < reList.length; m++) {
          if (!colCompList[m]) colCompList[m] = [];
          for (let z = 0; z < reList[m].length; z++) {
            let v = reList[m];
            let e = v[z];
            e.comp.id = "m" + parseInt(Math.random() * 10000000);
            if (e.comp.parent) {
              e.comp.parent.pid = obj.id;
              e.comp.parent.y = obj.basicConfigData.colIndex + (j - 1);
            }

            if (e.comp.transformData != undefined) {
              e.comp.resultData = adaptDataFn(e.comp, e.comp.transformData);
              e.comp.basicConfigData = e.comp.resultData;
              e.comp.jsonOption = JSON.stringify(
                e.comp.transformData,
                null,
                "\t"
              );
              e.comp.jsonCompJumpOption = getJumpCompParams(e.comp);
            }

            colCompList[m].push(JSON.parse(JSON.stringify(e)));
          }
        }
      }
      if (obj.repeatLayout.length > 0) {
        for (let n = 0; n < obj.repeatLayout.length; n++) {
          obj.repeatLayout[n] = [...obj.repeatLayout[n], ...colCompList[n]];
        }
      } else {
        obj.repeatLayout = [...colCompList];
      }

      obj.layoutDivision.cols = [...layoutColList];
    }
  }
};

//处理列表组件旧数据
const adpatLayoutData = obj => {
  for (let i = 0; i < obj.repeatLayout.length; i++) {
    if (obj.repeatLayout[i].length == 0) {
      obj.repeatLayout.splice(i, 1);
    }
  }

  let totalCols = 0,
    totalRows = 0;
  for (let i = 0; i < obj.repeatLayout.length; i++) {
    let colSpans = 0,
      rowSpans = 0;
    for (let j = 0; j < obj.repeatLayout[i].length; j++) {
      if (obj.repeatLayout[i][j].comp.colspan > 1) colSpans++;
      if (obj.repeatLayout[i][j].comp.rowspan > 1) rowSpans++;
    }
    if (colSpans == obj.repeatLayout[i].length) {
      totalCols++;
    }
    if (rowSpans == obj.repeatLayout[i].length) {
      totalRows++;
    }
    if (obj.repeatLayout[i].length == 0) {
      obj.repeatLayout.splice(i, 1);
    }
  }

  if (totalCols == obj.repeatLayout.length) {
    for (let i = 0; i < obj.repeatLayout.length; i++) {
      for (let j = 0; j < obj.repeatLayout[i].length; j++) {
        obj.repeatLayout[i][j].comp.colspan = 1;
      }
    }
    obj.layoutDivision.cols = [
      obj.layoutDivision.cols[0] * obj.repeatLayout[0] ? obj.repeatLayout[0].length : 0
    ];
  }

  if (totalRows == obj.repeatLayout.length) {
    for (let i = 0; i < obj.repeatLayout.length; i++) {
      for (let j = 0; j < obj.repeatLayout[i].length; j++) {
        obj.repeatLayout[i][j].comp.rowspan = 1;
      }
    }
    for (let i = 0; i < obj.repeatLayout.length; i++) {
      obj.layoutDivision.rows = [obj.layoutDivision.rows[0]];
    }
  }
};

//循环组件
const repeatCustomLayout = obj => {
  let result = adaptDataFn(obj, obj.basicConfigData);
  if (obj.parent) {
    refObj.parentParams.value[obj.id].pid = obj.parent.pid;
  }
  if (obj.type == "custom-layout1") {
    refObj.parentParams.value[obj.id] = result;
    if (refObj.repeatWidths[obj.id]) {
      obj.style.width = refObj.repeatWidths[obj.id].width;
      obj.style.height = refObj.repeatWidths[obj.id].height;
    }

    obj.reChildren = [];
    let repeatList = [],
      layoutList = [],
      width = parseInt(obj.style.width),
      height = parseInt(obj.style.height);
    for (let i = 0; i < obj.basicConfigData.children.length; i++) {
      if (!layoutList[parseInt(i / obj.basicConfigData.cols)])
        layoutList[parseInt(i / obj.basicConfigData.cols)] = [];

      let recordList = [];
      obj.children.forEach(v => {
        let record = JSON.parse(JSON.stringify(v));
        record.id = "m" + parseInt(Math.random() * 10000000);
        if (!record.parent) {
          record.parent = {
            ...obj.basicConfigData,
            pid: obj.id,
            index: i
          };
        } else {
          record.parent = { ...record.parent, ...obj.basicConfigData };
          record.parent.pid = obj.id;
          record.parent.index = i;
        }

        if (record.transformData) {
          let result = adaptDataFn(record, record.transformData);
          if (result.style) {
            record.style = result.style;
            record.resultData = result.data;
          } else {
            record.resultData = result;
          }
          record.basicConfigData = record.resultData;
          record.jsonOption = JSON.stringify(record.transformData, null, "\t");
          record.jsonCompJumpOption = getJumpCompParams(record);
        }
        recordList.push(record);
      });
      layoutList[parseInt(i / obj.basicConfigData.cols)].push(recordList);
    }

    if (layoutList[0]) {
      obj.style.width =
        (obj.basicConfigData.padding + width) * (layoutList[0].length - 1) +
        obj.style.width;
      for (let m = 0; m < layoutList.length; m++) {
        if (m > 0)
          obj.style.height =
            obj.basicConfigData.padding + height + obj.style.height;
        for (let j = 0; j < layoutList[m].length; j++) {
          for (let z = 0; z < layoutList[m][j].length; z++) {
            layoutList[m][j][z].style.left =
              (obj.basicConfigData.padding + width) * j +
              layoutList[m][j][z].style.left;
            layoutList[m][j][z].style.top =
              (obj.basicConfigData.padding + height) * m +
              layoutList[m][j][z].style.top;
          }
        }
      }

      for (let a = 0; a < layoutList.length; a++) {
        for (let b = 0; b < layoutList[a].length; b++) {
          for (let c = 0; c < layoutList[a][b].length; c++) {
            repeatList.push(layoutList[a][b][c]);
          }
        }
      }
    }

    obj.reChildren = [...repeatList];
  } else if (obj.type == "dynamic-board" && refObj.parentParams.value[obj.id]) {
    refObj.parentParams.value[obj.id].data = result;
  }
};

//页面
const choosePage = (item, isJump = false) => {
  screenObj.selectPage.value = { ...item };

  if(item.isBackground == 0){
    if (!flagObj.isPreview && flagObj.isEdit.value) {
      let list = repeatFn(screenObj.screen.value.page[item.id].components, v => {
        dealBackCompData(v);
        return v;
      });
      screenObj.screen.value.page[item.id].components = list;
    }
  
    if (!screenObj.screen.value.page[item.id])
      screenObj.screen.value.page[item.id] = { id: item.id, components: [] };

    if(screenObj.backgroundComp.value)
      screenObj.backgroundComp.value.children = screenObj.screen.value.page[screenObj.backgroundComp.value.id].components;
  } 
  refObj.compInfoList.value = [
    ...screenObj.screen.value.page[item.id].components
  ];
  if (refObj.layers.value)
    refObj.layers.value.pageSelectComps(refObj.compInfoList.value);

  screenObj.linePosObj.value = {};
  screenObj.linesPos.value = [[], [], [], [], [], []];
  screenObj.screen.value.page[item.id].components.forEach(v => {
    screenObj.linePosObj.value[v.id] = {
      x: [
        v.style.left,
        v.style.left + v.style.width / 2,
        v.style.left + v.style.width
      ],
      y: [
        v.style.top,
        v.style.top + v.style.height / 2,
        v.style.top + v.style.height
      ]
    };
  });
  if (refObj.stage.value) refObj.stage.value.freshComps();
  if (refObj.scene.value) refObj.scene.value.freshComps();
  if (refObj.oview.value) refObj.oview.value.freshComps();
  if (refObj.oviewRe.value) refObj.oviewRe.value.freshComps();

  screenObj.pageConfigList.value = _.filter(screenObj.pageInfos.value, v => {
    return v.id != item.id;
  });
};

const jumpPage = (item, isChart = false) => {
  if (flagObj.isEdit && !flagObj.isPreview) return;
  let parentComp = null;
  if (refObj.jumpParams.value) {
    parentComp = refObj.jumpParams;
  }
  let option = "";
  try {
    option = JSON.parse(item.param.value);
  } catch (error) {
    option = eval(`
    let option=${item.param.value};
    option;
    `);
  }
  refObj.jumpParams = option;

  // if(parentComp)
  //   refObj.jumpParams.parent = parentComp;
  if (!item.isSeries) {
    let obj = _.find(screenObj.pageInfos.value, v => {
      return v.id == item.pageId;
    });
    if (obj) choosePage(obj, true);
  } else {
    transferArgsInPages(item, true);
  }
};

const transferArgsInPages = obj => {
  let index = _.findIndex(
    screenObj.screen.value.page[screenObj.selectPage.value.id].components,
    v => {
      return v.id == obj.id;
    }
  );

  let opt =
    screenObj.screen.value.page[screenObj.selectPage.value.id].components[index]
      .jsonCompJumpOption;
  opt = adaptParamData(opt);
  let option = "";
  try {
    option = JSON.parse(opt);
  } catch (error) {
    option = eval(`
    let option=${opt};
    option;
    `);
  }

  let dataFunc = eval(
    screenObj.screen.value.page[screenObj.selectPage.value.id].components[index]
      .jumpDataFunc
  );
  let pageName = dataFunc(option);

  let page = _.find(screenObj.pageInfos.value, v => {
    return v.name == pageName;
  });
  if (page) choosePage(page, true);
};

//组件事件方法
//显示隐藏
const displayComp = (id, type, obj) => {
  let list = repeatFn(
    screenObj.screen.value.page[screenObj.selectPage.value.id].components,
    v => {
      if (v.id == id) {
        if (type == 1) {
          let dataFn = eval(replaceParamValue(obj, obj.eventHideFunc));
          let result = dataFn(obj.basicConfigData);
          if (result) {
            if(v.animateClass.out.show) {
              let hideTime = setTimeout(() => {
                v.active = false;
                v.style.display = false;
                Reflect.deleteProperty(v.animateClass.out, 'eventLoad');
                clearTimeout(hideTime);
              }, (parseInt(v.animateClass.out.time) + parseInt(v.animateClass.out.delay)) * 1000);
            }else {
              v.active = false;
              v.style.display = false;
            }
            
          }
        } else if (type == 2) {
          let dataFn = eval(replaceParamValue(obj, obj.eventShowFunc));
          let result = dataFn(obj.basicConfigData);
          if (result) {
            v.style.display = true;
              v.active = true;
            // if(v.animateClass.in.show) {
              
            //   let hideTime = setTimeout(() => {
            //     v.active = true;
            //     v.style.display = true;
            //     Reflect.deleteProperty(v.animateClass.in, 'eventLoad');
            //     clearTimeout(hideTime);
            //   }, (parseInt(v.animateClass.in.time) + parseInt(v.animateClass.in.delay)) * 1000);
            // }else {
              
            // }
          }
        } else if (type == 4) {
          let dataFn = eval(replaceParamValue(obj, obj.eventShowOrHideFunc));
          let result = dataFn(obj.basicConfigData);
          if (result) {
            
            if(v.style.display) {
              if(v.animateClass.out.show) {
                let hideTime = setTimeout(() => {
                  v.active = false;
                  v.style.display = false;
                  Reflect.deleteProperty(v.animateClass.out, 'eventLoad');
                  clearTimeout(hideTime);
                }, (parseInt(v.animateClass.out.time) + parseInt(v.animateClass.out.delay)) * 1000);
              }else {
                v.active = false;
                v.style.display = false;
              }
            }else {
              // if(v.animateClass.in.show) {
              //   let hideTime = setTimeout(() => {
              //     v.active = true;
              //     v.style.display = true;
              //     Reflect.deleteProperty(v.animateClass.in, 'eventLoad');
              //     clearTimeout(hideTime);
              //   }, (parseInt(v.animateClass.in.time) + parseInt(v.animateClass.in.delay)) * 1000);
              // }else {
                
              // }
              v.active = true;
                v.style.display = true; 
            }
            
          }
        }
      }
      return v;
    }
  );
  screenObj.screen.value.page[screenObj.selectPage.value.id].components = list;
};

const moveDynamicChildren = obj => {
  let distance = parseInt(obj.distance);
  let top = 0,
    left = 0;
  switch (obj.direction) {
    case 1:
      top = distance;
      break;
    case 2:
      top = 0 - distance;
      break;
    case 3:
      left = distance;
      break;
    case 4:
      left = 0 - distance;
      break;
    default:
      break;
  }
  let list = repeatFn(
    screenObj.screen.value.page[screenObj.selectPage.value.id].components,
    v => {
      if (
        v.id == obj.id &&
        (v.type == "dynamic-board" || v.type == "custom-layout1")
      ) {
        v.children.forEach(ch => {
          ch.style.top += top;
          ch.style.left += left;
        });
      } else if (v.id == obj.id) {
        v.style.top += top;
        v.style.left += left;
      }
      return v;
    }
  );

  screenObj.screen.value.page[screenObj.selectPage.value.id].components = list;
};

//刷新组件
const eventFreshComp = comp => {
  screenObj.screen.value.page[screenObj.selectPage.value.id].components = repeatFn(
    screenObj.screen.value.page[screenObj.selectPage.value.id].components,
    v => {
      let obj = _.find(comp.ids, o => {
        return o == v.id;
      });
      if (obj) {
        actionByType(v, () => {
          if (v.config && _.contains(staticObj.unContainKeys.value, v.type)) {
            saveDataV([v.id], v.basicConfigData);
          } else if (v.type == "three-map") {
            changeConfig(v.basicConfigData);
          } else if (v.type == "dynamic-board" || v.type == "custom-layout1") {
            v.children.forEach(ch => {
              actionByType(ch, () => {
                if (
                  ch.config &&
                  _.contains(staticObj.unContainKeys.value, ch.type)
                ) {
                  saveDataV([ch.id], ch.basicConfigData);
                } else if (ch.type == "three-map") {
                  changeConfig(ch.basicConfigData);
                }
              });
            });
          }
        });
      }
      return v;
    }
  );
};

const saveDataV = (ids, config) => {
  if (refObj.stage.value) refObj.stage.value.dataVInit(ids, config);
  if (refObj.scene.value) refObj.scene.value.dataVInit(ids, config);
  if (refObj.oview.value) refObj.oview.value.dataVInit(ids, config);
  if (refObj.oviewRe.value) refObj.oviewRe.value.dataVInit(ids, config);
};

const changeConfig = obj => {
  screenObj.screen.value.page[screenObj.selectPage.value.id].components.forEach(
    v => {
      if (v.id == refObj.currentNode.value.id) {
        v.basicConfigData = { ...refObj.currentNode.value.basicConfigData };
      }
    }
  );
  // if (refObj.stage.value)
  //   refObj.stage.value.changeConfig(refObj.currentNode.value.id, obj);
  // if (refObj.scene.value)
  //   refObj.scene.value.changeConfig(refObj.currentNode.value.id, obj);
  // if (refObj.oview.value)
  //   refObj.oview.value.changeConfig(refObj.currentNode.value.id, obj);
  // if (refObj.oviewRe.value)
  //   refObj.oviewRe.value.changeConfig(refObj.currentNode.value.id, obj);
};

//修改全局，页面，组件参数
const changeParamValue = (obj, tar) => {
  let fn = eval(replaceParamValue(obj, obj.paramDataFunc));
  let aData = replaceParamValue(obj, obj.basicConfigData);
  let result = fn(aData);
  if (tar.type == 1) {
    storeGlobal.forEach(g => {
      if (g.key == tar.key) g.value = result;
    });
  } else if (tar.type == 2) {
    screenObj.screen.value.page[
      screenObj.selectPage.value.id
    ].components.forEach(v => {
      if (v.id == "m00001") {
        v.params.forEach(p => {
          if (p.key == tar.key) p.value = result;
        });
      }
    });
  } else {
    obj.params[tar.key] = result;
    let list = repeatFn(
      screenObj.screen.value.page[screenObj.selectPage.value.id].components,
      data => {
        if (data.id == obj.id) {
          data.params.forEach(p => {
            if (p.key == tar.key) p.value = result;
          });
        }
        return data;
      }
    );
    screenObj.screen.value.page[
      screenObj.selectPage.value.id
    ].components = list;
  }
};

//修改样式事件
const changeEventStyle = (tar, animate) => {
  let list = repeatFn(
    screenObj.screen.value.page[screenObj.selectPage.value.id].components,
    v => {
      if (v.id == tar.id) {
        v.style = { ...v.style, ...getEventStyle(tar) };
        if(animate) {
          if(!v.style.display && v.animateClass.in.show && (v.animateClass.in.loadType == 2 || v.animateClass.in.loadType == 3)) {
            v.animateClass.in.eventLoad = true;
          }
          
          if(v.style.display && v.animateClass.out.show) {
            v.animateClass.out.eventLoad = true;
          }
        }
      }
      return v;
    }
  );
  screenObj.screen.value.page[screenObj.selectPage.value.id].components = list;
};

const getEventStyle = tar => {
  let result = {};
  switch (parseInt(tar.type)) {
    case 1:
      result.width = parseInt(tar.value);
      break;
    case 2:
      result.height = parseInt(tar.value);
      break;
    case 3:
      result.left = parseInt(tar.value);
      break;
    case 4:
      result.top = parseInt(tar.value);
      break;
    case 5:
      result.fontSize = parseInt(tar.value);
      break;
    case 6:
      result.color = tar.value;
      break;
    case 7:
      result.textAlign = tar.value;
      break;
    case 8:
      result.fontWeight = tar.value;
      break;
    case 9:
      result.fontStyle = tar.value;
      break;
    case 10:
      result.backgroundColor = tar.value;
      break;
  }
  return result;
};

const dealParamsDataFn = obj => {
  let data = replaceParamValue(obj, obj.basicConfigData);
  let dataFn = eval(replaceParamValue(obj, obj.paramsDealFunc));
  let result = dataFn(data);
  if (typeof result == "object") {
    for (let key in result.params) {
      //全局参数
      if (result.type == 0) {
        storeGlobal.forEach(g => {
          if (g.key == key) g.value = result.params[key];
        });
      } else if (result.type == 1) {
        //页面参数
        screenObj.screen.value.page[
          screenObj.selectPage.value.id
        ].components.forEach(v => {
          if (v.id == "m00001") {
            v.params.forEach(p => {
              if (p.key == key) p.value = result.params[key];
            });
          }
        });
      } else if (result.type == 2) {
        obj.params[key] = result.params[key];
        let list = repeatFn(
          screenObj.screen.value.page[screenObj.selectPage.value.id].components,
          data => {
            if (data.id == obj.id) {
              data.params.forEach(p => {
                if (p.key == key) p.value = result.params[key];
              });
            }
            return data;
          }
        );
        screenObj.screen.value.page[
          screenObj.selectPage.value.id
        ].components = list;
      }
    }
  }
};

//工具方法
const replaceParamValue = (comp, data) => {
  let str = data;
  if (typeof data == "object") {
    str = JSON.stringify(data);
  }
  let params;
  if (comp.parent && refObj.parentParams.value[comp.parent.pid]) {
    params = refObj.parentParams.value[comp.parent.pid];
    if (
      !refObj.parentParams.value[comp.parent.pid].isVertical &&
      (comp.parent.x == 0 || comp.parent.x)
    ) {
      params.x =
        comp.parent.x -
          (refObj.parentParams.value[comp.parent.pid]
            ? refObj.parentParams.value[comp.parent.pid].rowIndex
            : 0) <
        0
          ? 0
          : comp.parent.x - refObj.parentParams.value[comp.parent.pid].rowIndex;
    } else if (comp.parent.y == 0 || comp.parent.y) {
      params.y =
        comp.parent.y -
          (refObj.parentParams.value[comp.parent.pid]
            ? refObj.parentParams.value[comp.parent.pid].colIndex
            : 0) <
        0
          ? 0
          : comp.parent.y - refObj.parentParams.value[comp.parent.pid].colIndex;
    } else if (comp.parent.index == 0 || comp.parent.index) {
      params.index = comp.parent.index;
    }
  }
  str = adaptParamData(str, comp.params, params);
  if (typeof data == "object") {
    data = JSON.parse(str);
  } else {
    data = str;
  }

  return data;
};

const repeatFn = (list, callback) => {
  let result = [];
  list.forEach(v => {
    if (callback) {
      v = callback(v);
    }

    if (v.type == "layout" && v.repeatLayout) {
      v.repeatLayout.forEach(x => {
        x.forEach(y => {
          if (callback) {
            let obj = callback(y.comp);
            y.comp = obj;
          }
        });
      });
    }

    if (v.children && v.children.length > 0) {
      v.children = repeatFn(v.children, callback);
    }
    result.push(v);
  });
  list = result;
  return list;
};

const unionIDataAndCData = (obj, obj2) => {
  let result = obj2;
  //原始数据
  if (typeof obj == "object") {
    for (let key in obj) {
      if (
        typeof obj[key] == "string" ||
        typeof obj[key] == "number" ||
        typeof obj[key] == "boolean"
      ) {
        result[key] = obj[key];
      } else if (typeof obj[key] == "object" && !_.isArray(obj[key])) {
        if (!isContainObj(obj[key])) {
          if(_.isArray(obj2[key])) {
            result[key] = { ...obj2[0][key], ...obj[key] };
          }else {
            result[key] = { ...obj2[key], ...obj[key] };
          } 
        } else {
          result[key] = unionObject(obj[key], obj2[key], result[key]);
        }
      } else if (_.isArray(obj[key])) {
        if(typeof obj2[key] == "object" && !_.isArray(obj2[key])) {
          let list = [];
          for (let i = 0; i < obj[key].length; i++) {
            list[i] = unionObject(obj[key][i], obj2[key], result[key]);
          }
          result[key] = list;
        }else {
          result[key] = unionObject(obj[key], obj2[key], result[key]);
        }
        
      }
    }
  } else {
    result = obj;
  }
  return result;
};

const unionObject = (obj, obj2, result) => {
  if (!result) {
    result = obj;
    return result;
  }
  if (typeof obj == "object" && !_.isArray(obj)) {
    if (typeof result != "object") result = {};
    for (let key in obj) {
      if (
        typeof obj[key] == "string" ||
        typeof obj[key] == "number" ||
        typeof obj[key] == "boolean" ||
        !obj2[key]
      ) {
        result[key] = obj[key];
      } else {
        result[key] = unionObject(obj[key], obj2[key], result[key]);
      }
    }
  } else if (_.isArray(obj)) {
    let list = [];
    for (let i = 0; i < obj.length; i++) {
      let resultItem = {};
      if (
        typeof obj[i] == "string" ||
        typeof obj[i] == "number" ||
        typeof obj[i] == "boolean"
      ) {
        if (typeof obj2[i] == "object" && !_.isArray(obj2[i])) {
          result[i].value = obj[i];
          list[i] = result[i];
        } else {
          list[i] = obj[i];
        }
      } else if (typeof obj[i] == "object" && !_.isArray(obj[i])) {
        for (let key1 in obj[i]) {
          let isInObj2 = _.find(obj2, e => {
            return e[key1] != "undefind";
          });
          if (isInObj2) {
            if (
              typeof obj[i][key1] == "string" ||
              typeof obj[i][key1] == "number" ||
              typeof obj[i][key1] == "boolean"
            ) {
              resultItem[key1] = obj[i][key1];
            } else {
              resultItem[key1] = unionObject(
                obj[i][key1],
                !obj2[i] ? obj2[0][key1] : obj2[i][key1],
                !result[i] ? result[0][key1] : result[i][key1]
              );
            }
          } else {
            resultItem[key1] = obj[i][key1];
          }
        }
        if (!result[i]) result[i] = {};
        resultItem = { ...result[i], ...resultItem };
        list.push(resultItem);
      } else if (_.isArray(obj[i])) {
        let dataList = unionObject(
          obj[i],
          !obj2[i] ? obj2[0] : obj2[i],
          !result[i] ? result[0] : result[i]
        );
        list.push(dataList);
      }
    }
     result = list;
  }

  return result;
}; 

const isContainObj = obj => {
  let flag = false;
  for (let key in obj) {
    if (typeof obj[key] == "object") flag = true;
  }
  return flag;
};

const getJumpCompParams = obj => {
  let args = null;
  if (obj.jsonCompJumpOption) {
    args = JSON.parse(obj.jsonCompJumpOption);
    // args.value = obj.resultData;
  } else {
    args = {
      value: obj.resultData
    };
  }
  return JSON.stringify(args, null, "\t");
};

const adaptCompData = obj => {
  let grid = {
    show: false,
    top: 30,
    left: 30,
    right: 53,
    bottom: 43,
    containLabel: true
  };
  if (
    obj.type == "chart" ||
    (obj.config && (_.contains(staticObj.unContainKeys.value, obj.type) || _.contains(staticObj.otherConfigKey.value, obj.type))) || 
    (_.contains(staticObj.mapKeys.value, obj.type))
  ) {
    if (!obj.basicConfigData)
      obj.basicConfigData = JSON.parse(JSON.stringify(obj.resultData));
  } else {
    obj.basicConfigData = JSON.parse(JSON.stringify(obj.resultData));
  }

  if (obj.type == "chart" && !obj.isGL) {
    //直角坐标系内绘图网格
    if (!obj.basicConfigData.grid) {
      obj.basicConfigData.grid = { ...grid };
    } else {
      obj.basicConfigData.grid = { ...grid, ...obj.basicConfigData.grid };
    }

    //提示语
    if (obj.basicConfigData.tooltip && obj.basicConfigData.tooltip.textStyle) {
      obj.basicConfigData.isTooltip = true;
    } else obj.basicConfigData.isTooltip = false;

    if (!obj.basicConfigData.color) {
      obj.basicConfigData.color = gradualColor;
    }

    adaptSeries(obj);

    if (obj.basicConfigData.title == undefined) {
      obj.basicConfigData.isTitle = false;
    } else obj.basicConfigData.isTitle = true;

    //图例的类型
    if (obj.basicConfigData.legend == undefined) {
      obj.basicConfigData.isLegend = false;
    } else obj.basicConfigData.isLegend = true;
  } else if (obj.config) {
    switch (obj.type) {
      case "dv-active-ring-chart":
        if (obj.basicConfigData.showOriginValue == undefined)
          obj.basicConfigData.showOriginValue = false;
        if (obj.basicConfigData.radius == undefined)
          obj.basicConfigData.radius = 50;
        break;
      case "dv-scroll-ranking-board":
        if (obj.basicConfigData.waitTime == undefined)
          obj.basicConfigData.waitTime = 3000;
        break;
      case "dv-digital-flop":
        if (obj.basicConfigData.textAlign == undefined)
          obj.basicConfigData.textAlign = "center";
        break;
      case "dv-flyline-chart":
        if (obj.basicConfigData.lineWidth == undefined)
          obj.basicConfigData.lineWidth = 1;
        if (obj.basicConfigData.orbitColor == undefined)
          obj.basicConfigData.orbitColor = "";
        if (obj.basicConfigData.flylineColor == undefined)
          obj.basicConfigData.flylineColor = "#ffde93";
        if (obj.basicConfigData.curvature == undefined)
          obj.basicConfigData.curvature = 5;
        if (obj.basicConfigData.flylineRadius == undefined)
          obj.basicConfigData.flylineRadius = 100;
        if (obj.basicConfigData.relative == undefined)
          obj.basicConfigData.relative = true;
        break;
      case 'layout':
        if(!obj.basicConfigData.isVertical) {
          obj.basicConfigData.isVertical = false;
        }
        if(obj.basicConfigData.rowIndex == undefined) {
          obj.basicConfigData.rowIndex = 1;
        }
        if(obj.basicConfigData.colIndex == undefined) {
          obj.basicConfigData.colIndex = 1;
        }
        break;
      case 'custom-scroll-num':
        if(obj.basicConfigData.background == undefined) {
          obj.basicConfigData.background = '';
        }
        if(!obj.basicConfigData.color) {
          obj.basicConfigData.color = '#ff0';
        }
        if(!obj.basicConfigData.fontSize) {
          obj.basicConfigData.fontSize = 32;
        }
        if(obj.basicConfigData.border == undefined) {
          obj.basicConfigData.border = {
            width: 1,
            color: '#ff0'
          };
        }
        if(obj.basicConfigData.block == undefined) {
          obj.basicConfigData.block = {
            width: 40,
            height: 60
          };
        }
        if(obj.basicConfigData.borderRadius == undefined) {
          obj.basicConfigData.borderRadius = {
            radius: 0,
            topL: 0,
            topR: 0,
            bottomR: 0,
            bottomL: 0
          }
        }
        break;
      case 'bim_earth': 
        if(obj.basicConfigData.camera == undefined) {
          obj.basicConfigData.camera = {
            x: -2764214.9221867975,
            y: 4787760.688267581,
            z: 3170473.735383637,
            heading: .283185307179586,
            pitch: -1.5707963267948966,
            roll: 0,
            immediateFly: false
          };
        }
        obj.basicConfigData.data.forEach(v => {
          if(!v.arrowColor) {
            v.arrowColor = 'blue';
          }
          if(!v.floorColor) {
            v.floorColor = 'blue';
          }
          if(!v.anmiationFloorColor) {
            v.anmiationFloorColor = 'blue';
          }
          if(v.arrowScale == undefined) {
            v.arrowScale = 10;
          }
          if(v.floorScale == undefined) {
            v.floorScale = 10;
          }
          if(v.anmiationFloorScale == undefined) {
            v.anmiationFloorScale = 0.5;
          }
          if(v.textNear == undefined) {
            v.textNear = 0;
          }
          if(v.textFar == undefined) {
            v.textFar = 7306596;
          }
          if(v.near == undefined) {
            v.near = 0;
          }
          if(v.far == undefined) {
            v.far = 7306596;
          }
          if(v.labelOffset == undefined) {
            v.labelOffset = 100;
          }
        })
        break;
      default:
        break;
    }
  } else if (obj.isGL) {
    adaptGLData(obj);
  } else if (obj.type == 'choropleth' || obj.type == 'static') {
    adaptMapData(obj);
  }

  refObj.currentNode.value = obj;
};

const adaptSeries = obj => {
  let keys = ["line", "bar", "scatter"],
    axisTick = {
      show: true,
      splitNumber: 5,
      length: 6,
      distance: 10,
      lineStyle: {
        color: "#fff",
        width: 2
      }
    },
    splitLine = {
      show: true,
      length: 10,
      distance: 10,
      lineStyle: {
        color: "#fff",
        width: 2
      }
    },
    axisLabel = {
      show: true,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: 0,
      color: "#fff",
      distance: 15
    },
    axisLine = {
      lineStyle: {
        width: 30,
        color: [
          [0.3, "#67e0e3"],
          [0.7, "#37a2da"],
          [1, "#fd666d"]
        ]
      }
    },
    pointer = {
      itemStyle: {
        color: "inherit"
      }
    },
    detail = {
      valueAnimation: true,
      formatter: "{value} km/h",
      color: "inherit"
    };
  if (obj.basicConfigData.series && _.isArray(obj.resultData.series)) {
    if(obj.basicConfigData.series[0].type == 'treemap') {
      Reflect.deleteProperty(obj.basicConfigData, 'color');
    }else if(obj.basicConfigData.series[0].type == 'line'){
      obj.basicConfigData.color = colList;
    }
    obj.basicConfigData.series.forEach(v => {
      if (v.type == "pie") {
        if (v.clockwise == undefined) v.clockwise = true;
        if (v.roseType == undefined) v.roseType = false;
        if(v.center == undefined) v.center = ['50%', '50%'];

        if (_.isArray(v.radius)) {
          v.isRadius = true;
          v.innerRadius = v.radius[0];
          v.outRadius = v.radius[1];
        }else v.isRadius = false;

        if (v.emphasis == undefined) {
          v.emphasis = {
            scale: true,
            scaleSize: 10
          };
        }

        if (!v.label) {
          v.label = {
            show: false,
            position: "outside",
            fontSize: 0
          };
        }

        if (v.labelLine) {
          v.labelLine = {
            
            show: false,
            length: 15,
            lineStyle: {
              width: 1,
              type: "solid"
            },
            ...v.labelLine,
          };
        }
      } else if (v.type == "bar") {
        if(!v.barWidth) {
          v.barWidth = 20;
        }
        if(v.barGap == undefined) v.barGap= 1;
        if(!v.showBackground) {
          v.showBackground = false;
          v.backgroundStyle = {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
        if (v.barMaxWidth == undefined) v.barMaxWidth = 50;
        if (v.barMinHeight == undefined) v.barMinHeight = 0;
        if (v.itemStyle == undefined) {
          v.itemStyle = { borderRadius: 0 };
        }
      } else if (v.type == "line") {
        if (v.showSymbol == undefined) v.showSymbol = false;
        if (v.symbolSize == undefined) v.symbolSize = 12;
        if (v.symbol == undefined) v.symbol = "circle";
        if (v.step == undefined) v.step = false;
        if (v.lineStyle == undefined) {
          v.lineStyle = { width: 2 };
        }
        if (v.smooth == undefined) v.smooth = true;
        if (v.emphasis == undefined) {
          v.emphasis = { focus: "series" };
        }
        if (obj.chartType && obj.chartType == "stack") {
          v.areaStyle = {
            opacity: 0.8
          };
        }
      } else if (v.type == "scatter") {
        if (v.symbolSize == undefined) v.symbolSize = 4;
        if (v.symbol == undefined) v.symbol = "circle";
      } else if (v.type == "radar") {
        if (v.symbolSize == undefined) v.symbolSize = 4;
        if (v.symbol == undefined) v.symbol = "circle";
        if (v.lineStyle == undefined) {
          v.lineStyle = { width: 2 };
        }
      } else if (v.type == "gauge") {
        if (v.radius == undefined) v.radius = 160;
        if (v.startAngle == undefined) v.startAngle = 225;
        if (v.endAngle == undefined) v.endAngle = -45;
        if (v.splitNumber == undefined) v.splitNumber = 10;
        if (v.progress == undefined) {
          v.progress = {
            show: true,
            width: 10
          };
        }

        if (v.detail == undefined) {
          v.detail = { ...detail };
        } else {
          v.detail = { ...detail, ...v.detail };
        }

        if (v.axisLine == undefined) {
          v.axisLine = { ...axisLine };
        } else {
          v.axisLine = { ...axisLine, ...v.axisLine };
        }

        if (v.pointer == undefined) {
          v.pointer = { ...pointer };
        } else {
          v.pointer = { ...pointer, ...v.pointer };
        }

        if (v.splitLine == undefined) {
          v.splitLine = { ...splitLine };
        } else {
          v.splitLine = { ...splitLine, ...v.splitLine };
        }

        if (v.axisTick == undefined) {
          v.axisTick = { ...axisTick };
        } else {
          v.axisTick = { ...axisTick, ...v.axisTick };
        }

        if (v.axisLabel == undefined) {
          v.axisLabel = { ...axisLabel };
        } else {
          v.axisLabel = { ...axisLabel, ...v.axisLabel };
        }
      } else if (v.type == "funnel") {
        if (!v.gap) v.gap = 2;
        if (!v.min) v.min = 0;
        if (!v.max) v.max = 100;
        if (!v.top) v.top = 60;
        if (!v.bottom) v.bottom = 60;
        if (!v.tooltip) {
          v.tooltip = {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c}%"
          };
        }
        if (!v.toolbox) {
          v.toolbox = {
            feature: {
              dataView: { readOnly: false },
              restore: {},
              saveAsImage: {}
            }
          };
        }
        if (!v.label) {
          v.label = {
            show: true,
            position: "inside"
          };
        }
        if (!v.labelLine) {
          v.labelLine = {
            length: 10,
            lineStyle: {
              width: 1,
              type: "solid"
            }
          };
        }
        if (!v.itemStyle) {
          v.itemStyle = {
            borderColor: "#fff",
            borderWidth: 1
          };
        }
        if (!v.emphasis) {
          v.emphasis = {
            label: {
              fontSize: 20
            }
          };
        }
      } else if (v.type == "pictorialBar") {
        if (v.markLine) {
          if (!v.markLine.label) {
            v.markLine.label = {
              fontSize: 12,
              lineHeight: 0,
              color: "#fff",
              fontWeight: "normal",
              fontStyle: "normal"
            };
          }
          if (!v.markLine.lineStyle) {
            v.markLine = {
              ...v.markLine,
              lineStyle: {
                width: 1,
                color: "#fff"
              }
            };
          } else {
            v.markLine.lineStyle = {
              ...v.markLine.lineStyle,
              width: 1,
              color: "#fff"
            };
          }
        }
      } else if (v.type == "wordCloud") {
        if(!obj.isHide) obj.isHide = true;
        if (!v.width) v.width = 320;
        if (!v.height) v.height = 300;
        if (!v.shape) v.shape = 'star';
        if (!v.gridSize) v.gridSize = 6;
        if(!v.sizeRange) v.sizeRange = [12, 50];
        if(!v.textStyle) {
          v.textStyle = {
            color1: "'red'",
            colorStatic: 'red',
            emphasis: {
              shadowBlur: 10,
              shadowColor: '#333'
            }
          }
        }
      } 

      if (!v.label) {
        v.label = {
          show: false,
          position: "inside",
          fontSize: 0
        };
      }
      if (!v.labelLine) {
        v.labelLine = {
          show: false,
          length: 15,
          lineStyle: {
            width: 1,
            type: "solid"
          }
        };
      }
    });
    if (obj.basicConfigData.series[0].markLine == undefined) {
      obj.basicConfigData.openMarkLine = false;
    } else {
      obj.basicConfigData.openMarkLine = true;
    }

    if (obj.basicConfigData.series[0].data[0].itemStyle == undefined) {
      obj.basicConfigData.isColor = false;
    } else {
      obj.basicConfigData.isColor = true;
    }

    if (_.contains(keys, obj.basicConfigData.series[0].type)) {
      adaptLineData("xAxis", obj);
      adaptLineData("yAxis", obj);
    }
  } else if (typeof obj.basicConfigData.series == "object") {
    if (obj.basicConfigData.series.type == "bar") {
      if (obj.basicConfigData.radiusAxis == undefined) {
        obj.basicConfigData.radiusAxis = {
          axisLabel: {
            show: true,
            color: colWhite[8],
            fontSize: 16
          },
          axisLine: {
            lineStyle: {
              color: colWhite[2]
            }
          },
          axisTick: {
            lineStyle: {
              color: colWhite[2]
            }
          },
          minorTick: {
            lineStyle: {
              color: colWhite[2]
            }
          }
        }
      }else if(!obj.basicConfigData.radiusAxis.axisLabel){
        obj.basicConfigData.radiusAxis.axisLabel = {
          show: true,
          color: colWhite[8],
          fontSize: 16
        }
      }else if(!obj.basicConfigData.radiusAxis.axisLine){
        obj.basicConfigData.radiusAxis.axisLine = {
          lineStyle: {
            color: colWhite[2]
          }
        }
      }else if(!obj.basicConfigData.radiusAxis.axisTick){
        obj.basicConfigData.radiusAxis.axisTick = {
          lineStyle: {
            color: colWhite[2]
          }
        }
      }else if(!obj.basicConfigData.radiusAxis.minorTick){
        obj.basicConfigData.radiusAxis.minorTick = {
          lineStyle: {
            color: colWhite[2]
          }
        }
      }

      if (obj.basicConfigData.polar == undefined) {
        obj.basicConfigData.polar = {
          radius: [30, "80%"]
        };
      }

      if (obj.basicConfigData.series.coordinateSystem == undefined)
        obj.basicConfigData.series.coordinateSystem = "polar";

      if (obj.basicConfigData.series.label == undefined) {
        obj.basicConfigData.series.label = {
          show: true,
          position: "middle",
          formatter: "{b}: {c}"
        };
      }

      let angleAxis = {
        startAngle: 90,
        max: 4,
        clockwise: true,
        splitNumber: 15,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#000",
            width: 1
          }
        },
        axisTick: {
          show: true,
          inside: false,
          length: 5
        },
        axisLabel: {
          show: true,
          margin: 5,
          color: "#fff",
          fontSize: 12
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#000",
            width: 1
          }
        },
        splitArea: {
          show: true
        }
      };

      if (obj.basicConfigData.angleAxis == undefined) {
        obj.basicConfigData.angleAxis = { ...angleAxis };
      } else {
        obj.basicConfigData.angleAxis = {
          ...angleAxis,
          ...obj.basicConfigData.angleAxis
        };
      }
    }
  }
};

const adaptLineData = (key, obj) => {
  if (!obj.basicConfigData[key]) {
    if (key == "xAxis") {
      obj.basicConfigData.xAxis = {
        type: "category"
      };
    } else if (key == "yAxis") {
      obj.basicConfigData.yAxis = {
        type: "value"
      };
    }
  }
  if (obj.basicConfigData[key] && !_.isArray(obj.basicConfigData[key])) {
    if (!obj.basicConfigData[key].type) {
      if (key == "xAxis") {
        obj.basicConfigData[key].type = "category";
        if(obj.basicConfigData[key].splitArea == undefined) {
          obj.basicConfigData[key].splitArea = {
            show: false
          }
        }
      } else if (key == "yAxis") {
        obj.basicConfigData[key].type = "value";
      }
    }
    if (obj.basicConfigData[key].show == undefined)
      obj.basicConfigData[key].show = true;

    if (obj.basicConfigData[key].splitLine == undefined) {
      if (key == "xAxis") {
        obj.basicConfigData[key].splitLine = {
          show: false
        };
      } else if (key == "yAxis") {
        obj.basicConfigData[key].splitLine = {
          show: true,
          lineStyle: {
            type: "solid",
            color: "rgba(255,255,255,.2)"
          }
        };
      }
    }

    //轴标签
    if (obj.basicConfigData[key].axisLabel == undefined) {
      obj.basicConfigData[key].axisLabel = {
        show: true,
        fontSize: 14,
        lineHeight: 10,
        color: "rgba(255,255,255,.8)",
        rotate: 0
      };
    }

    //轴线
    if (obj.basicConfigData[key].axisLine == undefined) {
      obj.basicConfigData[key].axisLine = {
        show: false,
        symbol: "none",
        symbolLabel: "none"
      };
    } else {
      let symbol = obj.basicConfigData[key].axisLine.symbol;
      if (typeof symbol == "string") {
        obj.basicConfigData[key].axisLine.symbolLabel = symbol;
      } else if (_.isArray(symbol)) {
        obj.basicConfigData[key].axisLine.isSingle = true;
        if (symbol[0] == "none") {
          obj.basicConfigData[key].axisLine.isLeft = false;
          obj.basicConfigData[key].axisLine.symbolLabel = symbol[1];
        } else {
          obj.basicConfigData[key].axisLine.isLeft = true;
          obj.basicConfigData[key].axisLine.symbolLabel = symbol[0];
        }
      }
    }

    //轴刻度
    if (obj.basicConfigData[key].axisTick == undefined) {
      obj.basicConfigData[key].axisTick = {
        show: false,
        alignWithLabel: false,
        inside: false
      };
    }

    //轴名称字体样式
    if (obj.basicConfigData[key].nameTextStyle == undefined) {
      obj.basicConfigData[key].nameTextStyle = {
        fontSize: 12,
        lineHeight: 10,
        color: "rgba(255,255,255,.6)",
        padding: [0,0,0,20]
      };
    }
  } else if (_.isArray(obj.basicConfigData[key])) {
    obj.basicConfigData[key].forEach(v => {
      if (!v.type) {
        if (key == "xAxis") {
          v.type = "category";
          if(v.splitArea == undefined) {
            v.splitArea = {
              show: false
            }
          }
        } else if (key == "yAxis") {
          v.type = "value";
        }
      }
      if (v.show == undefined) v.show = true;

      if (v.splitLine == undefined) {
        if (key == "xAxis") {
          v.splitLine = {
            show: false
          };
        } else if (key == "yAxis") {
          v.splitLine = {
            show: true,
            lineStyle: {
              type: "solid",
              color: colWhite[2]
            }
          };
        }
      }

      //轴标签
      if (v.axisLabel == undefined) {
        v.axisLabel = {
          show: true,
          fontSize: 12,
          lineHeight: 10,
          color: ""
        };
      }
  
      //轴线
      if (v.axisLine == undefined) {
        v.axisLine = {
          show: true,
          symbol: "none",
          symbolLabel: "none"
        };
        if (key == "yAxis") {
          v.axisLine.show = false;
        }
      } else {
        let symbol = v.axisLine.symbol;
        if (typeof symbol == "string") {
          v.axisLine.symbolLabel = symbol;
        } else if (_.isArray(symbol)) {
          v.axisLine.isSingle = true;
          if (symbol[0] == "none") {
            v.axisLine.isLeft = false;
            v.axisLine.symbolLabel = symbol[1];
          } else {
            v.axisLine.isLeft = true;
            v.axisLine.symbolLabel = symbol[0];
          }
        }
      }

      //轴刻度
      if (v.axisTick == undefined) {
        v.axisTick = {
          show: true,
          alignWithLabel: false,
          inside: false
        };
      }

      //轴名称字体样式
      if (v.nameTextStyle == undefined) {
        v.nameTextStyle = {
          fontSize: 12,
          lineHeight: 10,
          color: colWhite[6],
          padding: [0, 0, 0, 20]
        };
      }
    });
  }
};

const adaptGLData = obj => {
  if (obj.basicConfigData.series && _.isArray(obj.resultData.series)) {
    if (obj.basicConfigData.series[0].type == "bar3D") {
      adaptGLLine(obj, "xAxis3D", "category");
      adaptGLLine(obj, "yAxis3D", "category");
      adaptGLLine(obj, "zAxis3D", "value");
      if (!obj.basicConfigData.grid3D) {
        obj.basicConfigData.grid3D = {
          boxWidth: 100,
          boxDepth: 40,
          viewControl: {
            // projection: 'orthographic'
          },
          light: {
            main: {
              intensity: 1.2,
              shadow: true
            },
            ambient: {
              intensity: 0.3
            }
          }
        };
      }
    } else if (obj.basicConfigData.series[0].type == "surface") {
      adaptGLLine(obj, "xAxis3D", "value");
      adaptGLLine(obj, "yAxis3D", "value");
      adaptGLLine(obj, "zAxis3D", "value");
      if (!obj.basicConfigData.grid3D) {
        obj.basicConfigData.grid3D = {
          viewControl: {
            // projection: 'orthographic'
          }
        };
      }
    }
    obj.basicConfigData.series.forEach(v => {
      if (v.type == "bar3D") {
        if (!v.emphasis) {
          v.emphasis = {
            label: {
              show: false,
              distance: 2,
              fontSize: 20,
              color: "#fff"
            },
            itemStyle: {
              color: "#900"
            }
          };
        }

        if (!v.label) {
          v.label = {
            show: false,
            fontSize: 16,
            borderWidth: 1,
            distance: 2,
            textStyle: {
              color: "#fff",
              fontSize: 20
            }
          };
        }

        if (!v.shading) {
          v.shading = "lambert";
        }
      } else if (v.type == "surface") {
        if (!v.wireframe) {
          v.wireframe = {
            show: true,
            lineStyle: {
              color: "#fff",
              width: 1
            }
          };
        }
        //  v.equation.z = eval(v.equation.z)
        // v.equation.z = fn(v.equation.x, v.equation.y)
      }
    });
  }
};

const adaptGLLine = (obj, key, value) => {
  if (obj.basicConfigData[key] && !obj.basicConfigData[key].type) {
    obj.basicConfigData[key] = {
      ...obj.basicConfigData[key],
      type: value,
      name: "",
      show: true,
      nameTextStyle: {
        color: "#fff",
        fontSize: 16
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#fff"
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#fff"
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          fontSize: 12,
          color: "#fff"
        }
      },
      axisTick: {
        show: true,
        length: 5,
        lineStyle: {
          width: 1,
          color: "#000"
        }
      }
    };
  } else {
    obj.basicConfigData[key] = {
      type: value,
      name: "",
      show: true,
      nameTextStyle: {
        color: "#fff",
        fontSize: 16
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#fff"
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#fff"
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          fontSize: 12,
          color: "#fff"
        }
      },
      axisTick: {
        show: true,
        length: 5,
        lineStyle: {
          width: 1,
          color: "#000"
        }
      }
    };
  }
};

const adaptMapData = obj => {
  if (obj.basicConfigData.series && _.isArray(obj.basicConfigData.series)) {
    if(obj.type == 'choropleth') {
      if(!obj.basicConfigData.backgroundColor) {
        obj.basicConfigData.backgroundColor = '';
      }

      if(obj.basicConfigData.visualMap.show == undefined) {
        obj.basicConfigData.visualMap.show = true;
      }

      if(!obj.basicConfigData.visualMap.min) {
        obj.basicConfigData.visualMap.min = 17;
      }
      if(!obj.basicConfigData.visualMap.max) {
        obj.basicConfigData.visualMap.max = 145;
      }

      if(!obj.basicConfigData.visualMap.top) {
        obj.basicConfigData.visualMap.top = 440;
      }
      if(!obj.basicConfigData.visualMap.left) {
        obj.basicConfigData.visualMap.left = 720;
      }
      if(!obj.basicConfigData.visualMap.right) {
        obj.basicConfigData.visualMap.right = 0;
      }
      if(!obj.basicConfigData.visualMap.bottom) {
        obj.basicConfigData.visualMap.bottom = 0;
      }

      obj.basicConfigData.series.forEach(v => {
        if(!v.itemStyle) {
          v.itemStyle = {
            "areaColor": "rgba(238,238,238,1)",
            "borderColor": "rgba(165,165,165,1)"
          }
        }
        if(!v.map) {
          v.map = 'china';
        }
        if(v.roam == undefined) {
          v.roam = false;
        }
      })
    }
  }else if(obj.type == 'static'){
    if(!obj.basicConfigData.backgroundColor) {
      obj.basicConfigData.backgroundColor = '#404a59';
    }
    if(!obj.basicConfigData.geo.label) {
      obj.basicConfigData.geo.label = {
        show: true,
        fontSize: 10,
        color: "rgb(231 224 13 / 94%)"
      }
    }
    if(!obj.basicConfigData.geo.itemStyle) {
      obj.basicConfigData.geo.itemStyle = {
        areaColor: "#323c48",
        borderColor: "#111"
      }
    }
    if(obj.basicConfigData.geo.roam == undefined) {
      obj.basicConfigData.geo.roam = false;
    }

    if(!obj.basicConfigData.geo.emphasis) {
      obj.basicConfigData.geo.emphasis = {
        label: {
          show: false,
          fontSize: 10,
          color: 'rgb(231 224 13 / 94%)'
        },
        itemStyle: {
          areaColor: "#60758e",
          borderColor: "#111"
        }
      }
    }
  }
};

const getParentIds = id => {
  let ids = [];
  repeatFn(
    screenObj.screen.value.page[screenObj.selectPage.value.id].components,
    v => {
      if (v.id == id) {
        ids.push(id);
        if (v.parent) {
          let arr = getParentIds(v.parent.pid);
          ids = [...ids, ...arr];
        }
      }
      return v;
    }
  );
  return ids;
};

const addChildComp = (type, callback) => {
  refObj.layers.value.addChildComp(type, callback);
};

const addScreenChildren = comp => {
  adaptOldData(comp);
  adaptLayoutComp(comp);
};

const adaptLayoutComp = v => {
  v.dataFunc = v.dataFunc
    ? v.dataFunc
    : "(data, style) => {return {data:data, style:style};}";
  // v.sqlDataFunc = v.sqlDataFunc
  //   ? v.sqlDataFunc
  //   : "(data, style) => {return {data:data, style:style};}";
  v.finalFunc = v.finalFunc
    ? v.finalFunc
    : "(data, style) => {return {data:data, style:style};}";
  // v.requestParamFunc = v.requestParamFunc
  //   ? v.requestParamFunc
  //   : "() => {return {}}";
  // v.requestHeaderFunc = v.requestHeaderFunc
  //   ? v.requestHeaderFunc
  //   : "() => {return {}}";
  // v.apiDataFunc = v.apiDataFunc
  //   ? v.apiDataFunc
  //   : "(data, style) => {return {data:data, style:style};}";
  v.modelDataFunc = v.modelDataFunc
    ? v.modelDataFunc
    : "(data, style) => {return {data:data, style:style};}";
  v.jumpDataFunc = v.jumpDataFunc ? v.jumpDataFunc : "(data) => {return '';}";
  v.paramDataFunc = v.paramDataFunc
    ? v.paramDataFunc
    : "(data) => {return data;}";
  v.eventShowFunc = v.eventShowFunc
    ? v.eventShowFunc
    : "(data) => {return true;}";
  v.eventHideFunc = v.eventHideFunc
    ? v.eventHideFunc
    : "(data) => {return true;}";
  v.eventShowOrHideFunc = v.eventShowOrHideFunc
    ? v.eventShowOrHideFunc
    : "(data) => {return true;}";
  v.paramsDealFunc = v.paramsDealFunc
    ? v.paramsDealFunc
    : "(data) => {return {type: 0, params: {}}};";
  getCompParams(v);
  v.basicConfigData = v.resultData;
  v.jsonOption = JSON.stringify(v.transformData, null, "\t");
  v.jsonCompJumpOption = getJumpCompParams(v);
  if (!v.apiResData) v.apiResData = "";
  if (!v.sqlResData) v.sqlResData = "";
  if (!v.sourceData.sql) v.sourceData.sql = "";
};

const chooseChildComp = comp => {
  adaptOldData(comp);
  otherObj.tabs.value = [
    {
      id: 0,
      name: "基础"
    },
    {
      id: 1,
      name: "数据"
    },
    {
      id: 2,
      name: "事件"
    },
    {
      id: 3,
      name: "参数"
    }
  ];
  if (refObj.config.value)
    refObj.config.value.tabClick(otherObj.tabs.value[0].id);
  adaptLayoutComp(comp);
  refObj.currentNode.value = comp;
  refObj.selectGroup.value = [];
  refObj.selectGroup.value.push(comp);
};

const mouseDownFn = (comp, showCenter) => {
  screenObj.linePosObj.value[comp.id] = {
    x: [
      comp.style.left,
      comp.style.left + comp.style.width / 2,
      comp.style.left + comp.style.width
    ],
    y: [
      comp.style.top,
      comp.style.top + comp.style.height / 2,
      comp.style.top + comp.style.height
    ]
  };
  screenObj.linesPos.value = [[], [], [], [], [], []];

  let selfObj = screenObj.linePosObj.value[comp.id];
  let list = [];

  if (!flagObj.dynamicEdit.value && !flagObj.repeatCompEdit.value) {
    list = screenObj.screen.value.page[screenObj.selectPage.value.id].components;
  } else {
    list = refObj.dynamicComp.value.children;
  }
  list.forEach(v => {
    if (
      v.id != comp.id &&
      v.id != "m00001" &&
      screenObj.linePosObj.value[v.id] &&
      v.style.display
    ) {
      let minX = 100,
        minY = 100; //最小距离
      let tarObj = screenObj.linePosObj.value[v.id];
      if (showCenter) {
        tarObj.x.forEach(item1 => {
          selfObj.x.forEach(item2 => {
            let distance = item1 - item2;
            if (Math.abs(distance) < optConfig.value.parameter.adsorption) {
              minX = Math.abs(minX) < Math.abs(distance) ? minX : distance;
            }
          });
        });

        if (Math.abs(minX) < optConfig.value.parameter.adsorption) {
          comp.style.left = comp.style.left + minX;
          selfObj.x = [
            comp.style.left,
            comp.style.left + comp.style.width / 2,
            comp.style.left + comp.style.width
          ]
        }

        tarObj.y.forEach(item1 => {
          selfObj.y.forEach(item2 => {
            let distance = item1 - item2;
            if (Math.abs(distance) < optConfig.value.parameter.adsorption) {
              minY = Math.abs(minY) < Math.abs(distance) ? minY : distance;
            }
          });
        });
        if (Math.abs(minY) < optConfig.value.parameter.adsorption) {
          comp.style.top = comp.style.top + minY;
          selfObj.y = [
            comp.style.top,
            comp.style.top + comp.style.height / 2,
            comp.style.top + comp.style.height
          ]
        }
      }
      for (let i = 0; i < selfObj.x.length; i++) {
        for (let j = 0; j < screenObj.linePosObj.value[v.id].x.length; j++) {
          if (selfObj.x[i] == screenObj.linePosObj.value[v.id].x[j]) {
            screenObj.linesPos.value[i + 3].push({
              type: j + 3,
              obj: v
            });
          }
        }
      }

      for (let m = 0; m < selfObj.y.length; m++) {
        for (let n = 0; n < screenObj.linePosObj.value[v.id].y.length; n++) {
          if (selfObj.y[m] == screenObj.linePosObj.value[v.id].y[n]) {
            screenObj.linesPos.value[m].push({
              type: n,
              obj: v
            });
          }
        }
      }
    }
  });
};

const gainPropValuesInComp = (comp) => {
  let result = [];
  switch(comp.type) {
    case 'text':
      result = [{
        name: '文本内容',
        type: 0,
        values: []
      }]
      break;
    case 'image':
      result = [{
        name: '图片地址',
        type: 0,
        values: []
      }]
      break;
    case 'clock':
      result = [{
        name: '时钟格式',
        type: 0,
        values: []
      }]
      break;
    case 'lantern':
      result = [{
        name: '走马灯内容',
        type: 0,
        values: []
      },
      {
        name: '走马灯配速',
        type: 0,
        values: []
      }]
      break;
    case 'progress':
      result = [{
        name: '进度',
        type: 0,
        values: []
      },
      {
        name: '颜色',
        type: 0,
        values: []
      }]
      break;
    case 'progress2':
      result = [{
        name: '进度',
        type: 0,
        values: []
      },
      {
        name: '颜色',
        type: 0,
        values: []
      },
      {
        name: '背景颜色',
        type: 0,
        values: []
      }]
      break;
    case 'iframe-comp':
      result = [{
        name: '链接',
        type: 0,
        values: []
      }]
      break;
    case 'video-comp':
      result = [{
        name: '链接',
        type: 0,
        values: []
      }]
      break;
    case 'custom-image1':
      result = [{
        name: '图片名称',
        type: 0,
        values: []
      },{
        name: '图片地址',
        type: 0,
        values: []
      }]
      break;
    case 'graph':
      result = [{
        name: '点',
        type: 0,
        values: []
      },{
        name: '点间距',
        type: 0,
        values: []
      },{
        name: '点半径',
        type: 0,
        values: []
      },
      {
        name: '点颜色',
        type: 0,
        values: []
      },{
        name: '点关系',
        type: 0,
        values: []
      },{
        name: '线宽',
        type: 0,
        values: []
      },
      {
        name: '线颜色',
        type: 0,
        values: []
      }]
      break;
    case 'chart':
      result = initChartData(comp);
      break;
    case 'dv-active-ring-chart':
      result.push({
        name: '数据',
        type: 0,
        values: []
      })
      break;
    case 'dv-scroll-board':
      result.push({
        name: '表头',
        type: 0,
        values: []
      },{
        name: '数据',
        type: 0,
        values: []
      })
      break;
    case 'dv-scroll-ranking-board':
    case 'dv-capsule-chart':
    case 'dv-water-level-pond':
      result.push({
        name: '数据',
        type: 0,
        values: []
      })
      break;
    case 'dv-percent-pond':
    case 'dv-digital-flop':
      result.push({
        name: '值',
        type: 0,
        values: []
      })
      break;
    case 'dv-flyline-chart':
      result.push({
        name: '中心点坐标',
        type: 0,
        values: []
      },{
        name: '点集合',
        type: 0,
        values: []
      })
      break;
    case 'static':
    case 'choropleth':
      result.push({
        name: '背景颜色',
        type: 0,
        values: []
      },{
        name: '字体颜色',
        type: 0,
        values: []
      },{
        name: '区域颜色',
        type: 0,
        values: []
      },{
        name: '边线颜色',
        type: 0,
        values: []
      },{
        name: '选中颜色',
        type: 0,
        values: []
      })
      break;
    case 'three-map': 
      result.push({
        name: '相机旋转',
        type: 0,
        values: []
      },{
        name: '相机位置',
        type: 0,
        values: []
      },{
        name: '相机缩放',
        type: 0,
        values: []
      },{
        name: '场景旋转',
        type: 0,
        values: []
      },{
        name: '场景位置',
        type: 0,
        values: []
      },{
        name: '场景缩放',
        type: 0,
        values: []
      },{
        name: '点移速',
        type: 0,
        values: []
      })
      break;
    case 'pyramid':
      result.push({
        name: '数据',
        type: 0,
        values: []
      },{
        name: '颜色',
        type: 0,
        values: []
      },{
        name: '间距',
        type: 0,
        values: []
      })
      break;
    case 'custom-scroll-num':
      result.push({
        name: '数字',
        type: 0,
        values: []
      },{
        name: '背景颜色',
        type: 0,
        values: []
      },{
        name: '颜色',
        type: 0,
        values: []
      }, {
        name: '边线宽度',
        type: 0,
        values: []
      }, {
        name: '边线颜色',
        type: 0,
        values: []
      }, {
        name: '数字框宽度',
        type: 0,
        values: []
      }, {
        name: '数字框高度',
        type: 0,
        values: []
      }, {
        name: '字体大小',
        type: 0,
        values: []
      })
      break;
    case 'bim_earth':
      result.push({
        name: '数据',
        type: 0,
        values: []
      },
      {
        name: '相机',
        type: 0,
        values: []
      })
      break;
    default:
      break;
  }
  return result;
};

const composePropValues = (comp, treeData, callback) => {
  let result;
  getModelContentById(comp.sourceData.prjModelId).then(data => {
    comp.sourceData.modelResData = data;
    let res = JSON.parse(data);
    let objData;
    try{
      objData = JSON.parse(res);
    }catch(e) {
      objData = res;
    }
    res = objData;
    comp.treePropValues = treeData;
    let valueFn= '', fnData= '';
    switch(comp.type) {
      case 'text':
      case 'image':
        if(comp.type == 'text') {
          result = '文本';
        }else {
          result = window.origin + '/images/login-bg.jpeg';
        }
        fnData = '';
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, null, (resData, fData) => {
            result = resData;
            fnData = fData;
          });
        })
        comp.modelDataFunc = `(data, style) => {return {data:${fnData}, style:style};}`;
        if(comp.type == 'image') {
          comp.fileUrl = comp.url = result;
        }
        break;
      case 'clock':
        result = {format:'yyyy-MM-dd hh-mm-ss', isWeek: false}
        fnData = '';
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, null, (resData, fData) => {
            result = resData;
            fnData = fData;
          });
          comp.modelDataFunc = `(data, style) => {return {data:{format:${fnData},isWeek:false}, style:style};}`;
        })
        break;
      case 'lantern':
        result = {text: '我是走马灯！！！', time: 400}
        let lantern = {text: '', time: ''}
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, null, (resData, fnData) => {
            if(v.name == '走马灯内容') {
              result.text = resData;
              lantern.text = fnData;
            }else if(v.name == '走马灯配速') {
              result.time = resData;
              lantern.time = fnData;
            }
          });
        })
        if(lantern.text) {
          comp.modelDataFunc = `(data, style) => {return {data:{text: ${lantern.text}, time: ${lantern.time}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data:data, style:style};}`;
        }
        
        comp.config = result;
        break;
      case 'progress':
        result = {progress: 30, color: "#409eff"}
        let progress = {progress: '', color: ''};
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, null, (resData, fnData) => {
            if(v.name == '进度') {
              result.progress = resData;
              progress.progress = fnData;
            }else if(v.name == '颜色') {
              result.color = resData;
              progress.color = fnData;
            }
          });
        })
        if(progress.progress) {
          comp.modelDataFunc = `(data, style) => {return {data:{progress: ${progress.progress}, color: ${progress.color}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data:data, style:style};}`;
        }
        
        comp.config = result;
        break;
      case 'progress2':
        result = {colorGradient: true, color: ["rgb(150,210,10)","rgb(0,255,200)"], bgColorGradient: true, bgColor: ["rgb(0,190,120)","rgb(0,190,190)"], data: {percent: 30}}
        let progress2 = {colorGradient: true, color: "", bgColorGradient: true, bgColor: "", data: {percent: ""}}
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name == '颜色') {
              result.color = resData;
              progress2.color = fnData;
            }else if(v.name == '背景颜色') {
              result.bgColor = resData;
              progress2.bgColor = fnData;
            }
          }, (resData, fnData) => {
            if(v.name == '进度') {
              result.progress = resData;
              progress.progress = fnData;
            }
          });
        })
        if(progress2.data.percent) {
          comp.modelDataFunc = `(data, style) => {return {data:{colorGradient: true, 
            color: ${progress2.color}, bgColorGradient: true, bgColor: ${progress2.bgColor}, 
            data: {percent: ${progress2.data.percent}}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data:data, style:style};}`;
        }
        
        comp.option = result;
        break;
      case 'iframe-comp':
        result = {url: 'http://ebp.blueearth.com.cn/login/index.html'}
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, null, (resData, fnData) => {
            result.url = resData;
            comp.modelDataFunc = `(data, style) => {return {data:{url:${fnData}}, style:style};}`;
          });
          
        })
        comp.config = result;
        break;
      case 'video-comp':
        result = {
          url: 'https://hls.vda.v.qcloudcdn.com/asp/adv/hls/2000/0303000a/3/default/5f840db8da9d151bc279ae539f6c94b6/2000.m3u8',
          "ctrlFlag": false,
          "type": 1,
          "loop": true
        }
        fnData = '';
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, null, (resData, fData) => {
            result.url = resData;
            fnData = fData;
            comp.modelDataFunc = `(data, style) => {return {data:{url:${fnData},ctrlFlag:false, type:1, loop: true}, style:style};}`;
          });
        })
        comp.config = result;
        break;
      case 'custom-image1':
        result = [{
          "name":"第一张图片",
          "src":"https://imgmall.tg.com.cn/kol/2019/2/27/1359/90AnAwcvk3SqTINje6B76biimUZQ.jpg?imageView2/2/w/1000"
        },
        {
          "name":"第二张图片",
          "src":"https://img0.baidu.com/it/u=3543131003,3381296545&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750"
        },
        {
          "name":"最后一张图片",
          "src":"https://pic.rmb.bdstatic.com/719a6dbe67fdcb1fdf5e5f75676d4413.jpeg"
        }]
        let imgNameList = [], imgSrcList = [], imgResult = {name: '', src: ''}, nameKeys = [], srcKeys = [];
        comp.treePropValues.forEach(v => {
          let imgData = adjustProp(res, v.values, v.type);
          let {propKey, keys} = gainArrayPropKeys(res, v);
          
          if(v.name == '图片名称') {
            imgNameList = imgData;
            imgResult.name = propKey;
            nameKeys = keys;
          }else {
            imgSrcList = imgData;
            imgResult.src = propKey;
            srcKeys = keys;
          }          
        })
        let fn = '';
        if(srcKeys.length > 0) {
          let i=1;
          srcKeys.forEach(key => {
            fn = fn + `${key}forEach(v${i} => {`
          })
          fn = fn + `list.push(v${i}.${imgResult.src}); `
          srcKeys.forEach(key => {
            fn = fn + `}); `
            i++;
          })
        }else {
          fn = fn + `list.push(${imgResult.src}); `
          imgSrcList = [imgSrcList]
        }
        if(nameKeys.length > 0) {
          let i=1;
          nameKeys.forEach(key => {
            fn = fn + `${key}forEach(v${i} => {`
          })
          fn = fn + `list2.push(v${i}.${imgResult.name}); `
          nameKeys.forEach(key => {
            fn = fn + `});`
            i++;
          })
        }else {
          fn = fn + `list2.push(${imgResult.name}); `
          imgNameList = [imgNameList]
        }
        comp.modelDataFunc = `(data, style) => {
          let list = [], list2 = [], result = []; ${fn} 
          for(let i=0;i< list.length;i++) {
            if(list2[i]) {
              result.push({
                name:list2[i],
                src: list[i]
              })
            }else {
              result.push({
                name:list2[list2.length - 1] ? list2[list2.length - 1] : '',
                src: list[i]
              })
            }
          }; 
          return {data: result, style:style};}`;

        let imgList = [];
        for(let i = 0; i< imgSrcList.length;i++) {
          if(imgSrcList[i]) {
            if(imgNameList[i]) {
              imgList.push({
                name: imgNameList[i],
                src: imgSrcList[i]
              })
            }else {
              imgList.push({
                name: imgNameList[imgNameList.length - 1] ? imgNameList[imgNameList.length - 1] : '',
                src: imgSrcList[i]
              })
            }
          } 
        }
        if(imgList.length > 0)
          result = [...imgList];
        comp.config = result;
        break;
      case 'graph':
        result = {points: [
          [
            {
              id: "1",
              name: "站点1",
            },
            {
              id: "2",
              name: "站点2",
            },
          ],
          [
            {
              id: "3",
              name: "站点1",
            },
            {
              id: "4",
              name: "站点2",
            },
          ],
          [
            {
              id: "5",
              name: "站点1",
            },
            {
              id: "6",
              name: "站点2",
            },
          ],
        ],
        config: {
          type: 3,
          r: 20,
          point: {
            space: 1,
            color: "#fff",
          },
          line: {
            width: 15,
            color: "#b3b3b3",
          },
        },
        relations: [
          ["2", "1", "4", "5", "6"],
          ["3", "4"],
        ],colors: [],}
        let graObj = {points: '',  config: {type: 3,r: 20,point: {space: 1,color: "#fff"},line: {width: 15,color: "#b3b3b3"}},relations: ''}
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name == '点') {
              result.points = resData;
              graObj.points = fnData;
            }else if(v.name == '点关系') {
              result.relations = resData;
              graObj.relations = fnData;
            }
          }, (resData, fnData) => {
          if(v.name == '点间距') {
            result.config.point.space = resData;
            graObj.config.point.space = fnData;
          }else if(v.name == '点半径') {
            result.config.r = resData;
            graObj.config.r = fnData;
          }else if(v.name == '点颜色') {
            result.config.point.color = resData;
            graObj.config.point.color = fnData;
          }else if(v.name == '线宽') {
            result.config.line.width = resData;
            graObj.config.line.width = fnData;
          }else if(v.name == '线颜色') {
            result.config.line.color = resData;
            graObj.config.line.color = fnData;
          }
          });
        })
        if(graObj.points) {
          comp.modelDataFunc = `(data, style) => {return {data:{points: ${graObj.points},  config: {type: ${graObj.config.type},r: ${graObj.config.r},point: {space: ${graObj.config.point.space},
          color: ${graObj.config.point.color}},line: {width: ${graObj.config.line.width},color: ${graObj.config.line.color}}},relations: ${graObj.relations}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data:data, style:style};}`;
        }
        
        break;
      case 'chart':
        result = dealChartData(comp, res);
        break;
      case 'dv-active-ring-chart':
        result = comp.resultData;
        let ringObj = {data: ''};
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name == '数据') {
              result.data = resData;
              ringObj.data = fnData;
            } 
          });
        })
        if(ringObj.data) {
          comp.modelDataFunc = `(data, style) => {return {data:{data: ${ringObj.data}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data:data, style:style};}`;
        }
        
        comp.config = result;
        break;
      case 'dv-scroll-board':
      case 'dv-scroll-ranking-board':
      case 'dv-capsule-chart':
      case 'dv-water-level-pond':
        result = comp.resultData;
        let boardObj = {data: '', header: ''};
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name == '数据') {
              result.data = resData;
              boardObj.data = fnData;
            }else if(v.name =='表头') {
              result.header = resData;
              boardObj.header = fnData;
            }
          });
        })
        if(boardObj.data) {
          if(result.header) {
            comp.modelDataFunc = `(data, style) => {return {data:{header: ${boardObj.header}, data: ${boardObj.data}}, style:style};}`;
          }else {
            comp.modelDataFunc = `(data, style) => {return {data:{data: ${boardObj.data}}, style:style};}`;
          }
        }else {
          comp.modelDataFunc = `(data, style) => {return {data: data, style:style};}`;
        }
        
        comp.config = result;
        break;
      case 'dv-percent-pond':
        result = comp.resultData;
        let percentObj = {value: ''};
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, null, (resData, fnData) => {
            if(v.name =='值') {
              result.value = resData;
              percentObj.value = fnData;
            }
          });
        })
        if(percentObj.value) {
          comp.modelDataFunc = `(data, style) => {return {data:{value: ${percentObj.value}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data:data, style:style};}`;
        }
        
        comp.config = result;
        break;
      case 'dv-digital-flop':
        result = comp.resultData;
        let numberObj = {number: ''};
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, null, (resData, fnData) => {
            if(v.name =='值') {
              result.number = resData;
              numberObj.number = fnData;
            }
          });       
        })
        if(numberObj.number) {
          comp.modelDataFunc = `(data, style) => {return {data:{number: ${numberObj.number}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data:data, style:style};}`;
        }
        
        comp.config = result;
        break;
      case 'dv-flyline-chart':
        result = comp.resultData;
        let flyMapObj = {center: '', points: ''};
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name =='中心点坐标') {
              result.centerPoint = resData;
              flyMapObj.center = fnData;
            }else if(v.name =='点集合') {
              result.points = resData;
              flyMapObj.points = fnData;
            }
          }); 
        })
        if(flyMapObj.center) {
          comp.modelDataFunc = `(data, style) => {return {data:{centerPoint: ${flyMapObj.center}, points: ${flyMapObj.points}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data:data, style:style};}`;
        }
        
        comp.config = result;
        break;
      case 'static':
      case 'choropleth':
        result = comp.resultData;
        let staticObj = {background: '', font: '', border: '', area:'', emph:''};
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, null, (resData, fnData) => {
            if(v.name =='背景颜色') {
              result.backgroundColor = resData;
              staticObj.background = fnData;
            }else if(v.name =='字体颜色') {
              result.geo.label.color = resData;
              staticObj.font = fnData;
            }else if(v.name =='区域颜色') {
              result.geo.itemStyle.areaColor = resData;
              staticObj.area = fnData;
            }else if(v.name =='边线颜色') {
              result.geo.itemStyle.borderColor = resData;
              staticObj.border = fnData;
            }else if(v.name =='选中颜色') {
              result.geo.emphasis.itemStyle.areaColor = resData;
              staticObj.emph = fnData;
            }
          });    
        })
        if(staticObj.background) {
          comp.modelDataFunc = `(data, style) => {
            let obj = {
              "backgroundColor": ${staticObj.background},
              "geo": {
                "map": "china",
                "roam": false,
                "zoom": 1.23,
                "label": {
                  "show": true,
                  "fontSize": "10",
                  "color": ${staticObj.font}
                },
                "itemStyle": {
                  "areaColor": ${staticObj.area},
                  "borderColor": ${staticObj.border}
                },
                "emphasis": {
                  "label": {
                    "show": false
                  },
                  "itemStyle": {
                    "areaColor": ${staticObj.emph}
                  }
                }
              }
            }
            return {data: obj, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data: data, style:style};}`;
        }
        
        comp.option = result;
        break;
      case 'three-map':
        result = comp.resultData;
        let threeMapObj = {camera: {rotation: '',position: '',scale:''}, scene: {rotation: '',position: '',scale:''}, pointSpeed: ''};
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name =='相机旋转') {
              result.camera.rotation = resData;
              threeMapObj.camera.rotation = fnData;
            }else if(v.name =='相机位置') {
              result.camera.position = resData;
              threeMapObj.camera.position = fnData;
            }else if(v.name =='相机缩放') {
              result.camera.scale = resData;
              threeMapObj.camera.scale = fnData;
            }else if(v.name =='场景旋转') {
              result.scene.rotation = resData;
              threeMapObj.scene.rotation = fnData;
            }else if(v.name =='场景位置') {
              result.scene.position = resData;
              threeMapObj.scene.position = fnData;
            }else if(v.name =='场景缩放') {
              result.scene.scale = resData;
              threeMapObj.scene.scale = fnData;
            }else if(v.name =='点移速') {
              result.pointSpeed = resData;
              threeMapObj.pointSpeed = fnData;
            }
          });    
        })
        if(threeMapObj.camera.rotation) {
          comp.modelDataFunc = `(data, style) => {
            let obj = {
              "camera": {
                "rotation": ${threeMapObj.camera.rotation},
                "position": ${threeMapObj.camera.position},
                "scale": ${threeMapObj.camera.scale}
              },
              "scene": {
                "rotation": ${threeMapObj.scene.rotation},
                "position": ${threeMapObj.scene.position},
                "scale": ${threeMapObj.scene.scale}
              },
              "pointSpeed": ${threeMapObj.pointSpeed}
            }
            return {data: obj, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data: data, style:style};}`;
        }
        comp.config = result;
        break;
      case 'pyramid':
        result = {
          canvData: [{
              name:'第一个',
              value: 1,
              show: true
          },
          {
              name:'第二个',
              value: 2,
              show: true
          },
          {
              name:'第三个',
              value: 3,
              show: true
          }],
          col: ['rgba(220,174,66,.8)', 'rgba(25, 140, 240, .8)', 'rgba(30, 180, 240, .8)'],
          distance: 10
        }
        let canvResult = {canvData: '', col: '', distance:''};
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fData) => {
            if(v.name == '数据') {
              result.canvData = resData;
              canvResult.canvData = fData;
            }else if(v.name == '颜色'){
              result.col = resData;
              canvResult.col = fData;
            }else if(v.name == '间距') {
              result.distance = resData;
              canvResult.distance = fData;
            }   
          });
        })
        
        if(canvResult.canvData) {
          comp.modelDataFunc = `(data, style) => {return {data:{canvData: ${canvResult.canvData}, col: ${canvResult.col}, distance: ${canvResult.distance}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data:data, style:style};}`;
        }

        comp.config = result;
        break;
      case 'custom-scroll-num':
        result = {
          number: 184,
          background: '',
          color: "#ff0",
          border: {
            width: 1,
            color: '#ff0'
          },
          block: {
            width: 40,
            height: 60
          },
          fontSize: 32
        }
        valueFn = '';
        let scNumResult = {number: '', background: '', color:'', border: {width: '', color: ''}, block: {width: '', height:''}, fontSize: ''};
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fData) => {
            if(v.name == '数字') {
              result.number = resData;
              scNumResult.number = fData;
            }else if(v.name == '背景颜色'){
              result.background = resData;
              scNumResult.background = fData;
            }else if(v.name == '颜色') {
              result.color = resData;
              scNumResult.color = fData;
            }else if(v.name == '边线宽度') {
              result.border.width = resData;
              scNumResult.border.width = fData;
            }else if(v.name == '边线颜色') {
              result.border.color = resData;
              scNumResult.border.color = fData;
            }else if(v.name == '数字框高度') {
              result.block.height = resData;
              scNumResult.block.height = fData;
            }else if(v.name == '数字框宽度') {
              result.block.width = resData;
              scNumResult.block.width = fData;
            }else if(v.name == '字体大小') {
              result.fontSize = resData;
              scNumResult.fontSize = fData;
            }
          });
        })
        
        if(scNumResult.number) {
          valueFn += `data.number = ${scNumResult.number};`
          comp.modelDataFunc = `(data, style) => {return {data:{number: ${scNumResult.number}, background: ${scNumResult.background}, color: ${scNumResult.color},
        border: {width:${scNumResult.border.width},color: ${scNumResult.border.color}}, block: {width: ${scNumResult.block.width}, height: ${scNumResult.block.height}},
        fontSize: ${scNumResult.fontSize}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data:data, style:style};}`;
        }

        comp.config = result;
        break;
      case 'bim_earth':
        result = {
          data:[
            {
              id: '1', 
              name: '项目名称',
              lng: 120, 
              lat: 30, 
              height: 30, 
            },
            {
              id: '2', 
              name: '项目名称2',
              lng: 120, 
              lat: 29, 
              height: 30, 
            }
          ]
        };
        let earthObj = {data: '', camera: ''}
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fData) => {
            if(v.name == '数据') {
              result.data = resData;
              earthObj.data = fData;
            }else if(v.name == '相机'){
              result.camera = resData;
              earthObj.camera = fData;
            }
          }, null);
        })
        comp.modelDataFunc = `(data, style) => {return {data:{data: ${earthObj.data}, camera: ${earthObj.camera}}, style:style};}`;
      default:
        break;
    }

    comp.transformData = result;

    if (
      comp.type == "chart" ||
      (comp.config && (_.contains(staticObj.unContainKeys.value, comp.type) || _.contains(staticObj.otherConfigKey.value, comp.type)))
    ) {
      let resultData = unionIDataAndCData(
        result,
        comp.basicConfigData
      );
      comp.basicConfigData = comp.resultData = resultData;
    }else {
      comp.basicConfigData = comp.resultData = result;
    }
    let args = {
      value: comp.resultData
    }
    comp.jsonCompJumpOption = JSON.stringify(args, null, "\t");
    comp.jsonOption = JSON.stringify(comp.transformData, null, "\t");

    if(refObj.selectGroup.value.length > 0) {
      refObj.currentNode.value = refObj.selectGroup.value[0] = {...comp};
    }
      
    let list = repeatFn(screenObj.screen.value.page[screenObj.selectPage.value.id].components, v => {
      if(v.id == comp.id) {
        v = {...comp};
      }
      return v;
    })
    screenObj.screen.value.page[screenObj.selectPage.value.id].components = list;

    nextTick(() => {
      switch(comp.type) {
        case 'lantern':
          if (refObj.stage.value) refObj.stage.value.reStartConfig(comp.id);
          if (refObj.scene.value) refObj.scene.value.reStartConfig(comp.id);
          break;
        case 'graph':
          if (refObj.stage.value) refObj.stage.value.changeGraph(comp.id);
          if (refObj.scene.value) refObj.scene.value.changeGraph(comp.id);
          break;
        case 'chart':
        case 'static':
        case 'choropleth':
          if (refObj.stage.value) refObj.stage.value.refreshComp([comp.id]);
          if (refObj.scene.value) refObj.scene.value.refreshComp([comp.id]);
          break;
        case 'dv-active-ring-chart':
        case 'dv-scroll-board':
        case 'dv-scroll-ranking-board':
        case 'dv-capsule-chart':
        case 'dv-water-level-pond':
        case 'dv-percent-pond':
        case 'dv-digital-flop':
        case 'dv-flyline-chart':
          if (refObj.stage.value) refObj.stage.value.dataVInit([comp.id], comp.basicConfigData);
          if (refObj.scene.value) refObj.scene.value.dataVInit([comp.id], comp.basicConfigData);
          break;
        default:
          break;
      }
    })
    if(callback) {
      callback();
    }
  });
  
};

const gainArrayPropKeys = (res, v) => {
  let imgObj = res, completes = 'data', forEachKeys = [];
  v.values.forEach(val=> {
    let keys = 'data.';
    val.PropKeys.forEach(p => {
      imgObj = imgObj[p];
      completes = completes + '.' + p;
      keys = keys + p + '.';
      if(_.isArray(imgObj)) {
        forEachKeys.push(keys);
        keys = '';
      }
    })
  })
  let propKey = '';
  if(forEachKeys.length > 0) {
    forEachKeys.forEach(key => {
      propKey = completes.replace(key, '');
    })
  }else {
    propKey = completes;
  }
  return {
    propKey: propKey,
    keys: forEachKeys
  };
};

const modelCommonCode = (res, v, beforeCallback, afterCallback) => {
  let resData = adjustProp(res, v.values, v.type);
          
  let fnData = '', forEachKeys = [];
  if(_.isArray(resData)) {
    let {propKey, keys} = gainArrayPropKeys(res, v);
    fnData = propKey;
    forEachKeys = keys;
  }else {
    v.values.forEach(e => {
      let keys = 'data.';
      e.PropKeys.forEach(p => {
        keys = keys + p + '.';
      })
      keys = keys.substring(0, keys.length - 1);
      fnData = keys + "+";
    }) 
    if(fnData)
      fnData = fnData.substring(0, fnData.length - 1);
    else 
      fnData = "data";
  }
  if(resData) {
    if(beforeCallback) {
      beforeCallback(resData, fnData);
    }  
    if(afterCallback) {
      if(_.isArray(resData)) {
        resData = resData[0];
        forEachKeys.forEach(e => {
          fnData = fnData.replace(e, '');
          let f = e.substring(0, e.length - 1);
          fnData = fnData.replace(f, f + '[0]');
        })
      }
      afterCallback(resData, fnData);
    }
  }
  

};

const adjustProp = (res, values, type) => {
  let result, list = [], obj;
  if(typeof res == 'object' && !_.isArray(res)) {
    values.forEach(v => {
      obj = res; 
      v.PropKeys.forEach(p => {
        if(_.isArray(obj)) {
          let keys = [];
          obj.forEach(key => {
            keys.push(key[p]);
          })
          obj = [...keys];
        }else if(obj){
          obj = obj[p];
        }  
      })
      list.push(obj);
    }) 
    result = useFnData(list, type);
  }else if(_.isArray(res)) {
    let list2 = [];
    res.forEach(e => {
      obj = e;
      values.forEach(v => {
        v.PropKeys.forEach(p => {
          obj = obj[p];
        })
        list.push(obj);
      }) 
      let data = useFnData(list, type);
      list2.push(data);
    })
    result = [...list2];
  }
  return result;
};

const useFnData = (list, type) => {
  let result;
  if(list.length > 0) {
    if(typeof list[0] == 'string')
      result = '';
    else if(typeof list[0] == 'number')
      result = 0;
  }
  if(typeof list[0] != 'object') {
    switch(type) {
      case 0://求和
        list.forEach(v => {
          result += v;
        })
        break;
      case 1://均值
        list.forEach(v => {
          result += v;
        })
        result = result/list.length;
        break;
      case 2: //最大值
        result = _.max(list, v => {return v;});
        break;
      case 3: //最小值
        result = _.min(list, v => {return v;});
        break;
      case 4: //计数
        result = list.length;
        break;
      case 5: //去重计数
        let list2 = _.uniq(list);
        result = list2.length;
        break;
      default:
        break;
    }
  }else {
    result = list[0];
  }
  
  return result;
};

const initChartData = (comp) => {
  let result = [];
  if(comp.basicConfigData.series && _.isArray(comp.basicConfigData.series)) {
    switch(comp.basicConfigData.series[0].type) {
      case 'bar':
      case 'line':
      case 'scatter':
      case 'pie':
      case 'gauge':
      case 'funnel':
      case 'treemap':
      case 'pictorialBar':
      case 'wordCloud':
        let i = 1;
        comp.basicConfigData.series.forEach(v => {
          if(i == 1) {
            result.push({
              name: '数据',
              index: i-1,
              type: 0,
              values: []
            })
          }else {
            result.push({
              name: '数据' + i,
              index: i-1,
              type: 0,
              values: []
            })
          }
         
          i++;
        })
        if(comp.basicConfigData.xAxis && _.isArray(comp.basicConfigData.xAxis)) {
          result.push({
            name: 'X轴',
            type: 0,
            values: []
          })
        }
        if(comp.basicConfigData.yAxis && _.isArray(comp.basicConfigData.yAxis)) {
          result.push({
            name: 'Y轴',
            type: 0,
            values: []
          })
        }

        if(comp.basicConfigData.xAxis && comp.basicConfigData.xAxis.data) {
          result.push({
            name: 'X轴',
            type: 0,
            values: []
          })
        }else if(comp.basicConfigData.yAxis && comp.basicConfigData.yAxis.data) {
          result.push({
            name: 'Y轴',
            type: 0,
            values: []
          })
        }

        if(comp.basicConfigData.color) {
          result.push({
            name: '颜色',
            type: 0,
            values: []
          })
        }
        break;
      case 'radar':
        let j = 1;
        comp.basicConfigData.series[0].data.forEach(v => {
          if(j == 1) {
            result.push({
              name: '数据',
              index: j-1,
              type: 0,
              values: []
            })
          }else {
            result.push({
              name: '数据' + j,
              index: j-1,
              type: 0,
              values: []
            })
          }
          
          j++;
        })
        if(comp.basicConfigData.radar && comp.basicConfigData.radar.indicator) {
          result.push({
            name: '指示标识',
            type: 0,
            values: []
          })
        }
        break;
      case 'bar3D':
        let z = 1;
        comp.basicConfigData.series.forEach(v => {
          if(z == 1) {
            result.push({
              name: '数据',
              index: z-1,
              type: 0,
              values: []
            })
          }else {
            result.push({
              name: '数据' + z,
              index: z-1,
              type: 0,
              values: []
            })
          }
          
          i++;
        })
        if(comp.basicConfigData.xAxis3D.data) {
          result.push({
            name: 'X轴',
            type: 0,
            values: []
          })
        }
        if(comp.basicConfigData.yAxis3D.data) {
          result.push({
            name: 'Y轴',
            type: 0,
            values: []
          })
        }
        if(comp.basicConfigData.zAxis3D.data) {
          result.push({
            name: 'Y轴',
            type: 0,
            values: []
          })
        }
        break;
      case 'surface':
        result.push({
          name: 'X轴',
          type: 0,
          values: []
        },{
          name: 'Y轴',
          type: 0,
          values: []
        },{
          name: 'Z轴',
          type: 0,
          values: []
        })
        break;
      default:
        break;
    }
  }else if(comp.basicConfigData.series && typeof comp.basicConfigData.series == 'object') {
    switch(comp.basicConfigData.series.type) {
      case 'bar':
        result.push({
          name: '数据',
          type: 0,
          values: []
        })
        if(comp.basicConfigData.radiusAxis && comp.basicConfigData.radiusAxis.data) {
          result.push({
            name: '半径轴',
            type: 0,
            values: []
          })
        }
        break;
      default:
        break;
    }
  }else if(comp.basicConfigData.graphic && comp.basicConfigData.graphic.elements[0].type == 'text') {
    result.push({
      name: '数据',
      type: 0,
      values: []
    })
  }
  return result;
};

const dealChartData = (comp, res) => {
  let result;
  if(comp.basicConfigData.series && _.isArray(comp.basicConfigData.series)) {
    switch(comp.basicConfigData.series[0].type) {
      case 'bar':
      case 'line':
      case 'scatter':
      case 'pie':
      case 'gauge':
      case 'funnel':
      case 'treemap':
      case 'pictorialBar':
      case 'wordCloud':
        result = comp.resultData;
        let barObj = {xAxis: '', data:[], yAxis: '', color: '', radar: ''}
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name.indexOf('数据') != -1) {
              if(comp.basicConfigData.series[0].type == 'radar') {
                result.series[0].data[v.index] = resData;
              }else {
                result.series[v.index].data = resData;
              }
              barObj.data[v.index] = fnData;
            }else if(v.name == 'X轴') {
              if(_.isArray(result.xAxis)) {
                result.xAxis.forEach(e => {
                  e.data = resData;
                })
              }else {
                result.xAxis.data = resData;
              }
              
              barObj.xAxis = fnData;
            }else if(v.name == 'Y轴') {
              if(_.isArray(result.yAxis)) {
                result.yAxis.forEach(e => {
                  e.data = resData;
                })
              }else {
                result.yAxis.data = resData;
              }
              barObj.yAxis = fnData;
            }else if(v.name == '颜色') {
              if(_.isArray(resData))
                result.color = resData;
              else {
                result.color = [resData];
              }
              barObj.color = fnData;
            }else if(v.name == '指示标识') {
              result.radar.indicator = resData;
              barObj.radar = fnData;
            }
          });
        })
        if(barObj.data) {
          let resStr = `let obj = {};let list = [${barObj.data}], series = []; for(let i = 0; i< list.length; i++) {series.push({data: list[i]});}; obj.series = series;`;
          if(comp.basicConfigData.xAxis) {
            if(_.isArray(comp.basicConfigData.xAxis) && comp.basicConfigData.xAxis[0].data) {
              resStr += `obj.xAxis = [{data:${barObj.xAxis}}];` 
            }else if(comp.basicConfigData.xAxis.data){
              resStr += `obj.xAxis = {data:${barObj.xAxis}};` 
            }
          }
  
          if(comp.basicConfigData.yAxis) {
            if(_.isArray(comp.basicConfigData.yAxis) && comp.basicConfigData.yAxis[0].data) {
              resStr += `obj.yAxis = [{data:${barObj.yAxis}}];` 
            }else if(comp.basicConfigData.yAxis.data){
              resStr += `obj.yAxis = {data:${barObj.yAxis}};` 
            } 
          } 
  
          if(comp.basicConfigData.color) {
            resStr += `obj.color = [${barObj.color}];`
          }
  
          comp.modelDataFunc = `(data, style) => {${resStr} return {data: obj, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data: data, style:style};}`;
        }
        
        comp.option = result;
        //临时处理合并数据问题
        if(comp.basicConfigData.series[0].type == 'treemap') {
          comp.basicConfigData.series[0].data = result.series[0].data;
        }
        break;
      case 'radar':
        result = comp.resultData;
        let radarObj = {data:[], radar: ''}
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name.indexOf('数据') != -1) {
              result.series[0].data[v.index] = resData;
              radarObj.data[v.index] = fnData;
            }else if(v.name == '指示标识') {
              result.radar.indicator = resData;
              radarObj.radar = fnData;
            }
          });
        })
        if(radarObj.data) {
          comp.modelDataFunc = `(data, style) => {let list = [${radarObj.data}]; let series = []; for(let i =0;i< list.length; i++) {
            let data = []; 
            data.push({value: list[i]}); 
            series.push({data: data});
          }; 
          return {data: {radar: {indicator: ${radarObj.radar}}, series: series}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data: data, style:style};}`;
        }
        
        comp.option = result;
        break;
      case 'bar3D':
        result = comp.resultData;
        let bar3DObj = {xAxis3D: '', data:[], yAxis3D: '', zAxis3D: '', color: ''}
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name.indexOf('数据') != -1) {
              result.series[v.index].data = resData;
              bar3DObj.data[v.index] = fnData;
            }else if(v.name == 'X轴') {
              result.xAxis3D.data = resData;
              bar3DObj.xAxis3D = fnData;
            }else if(v.name == 'Y轴') {
              result.yAxis3D.data = resData;
              bar3DObj.yAxis3D = fnData;
            }else if(v.name == 'Z轴') {
              result.zAxis3D.data = resData;
              bar3DObj.zAxis3D = fnData;
            }
          }); 
        })
        if(bar3DObj.data) {
          let res3DStr = `let obj = {};let list = [${bar3DObj.data}], series = []; for(let i = 0; i< list.length; i++) {series.push({data: list[i]});}; obj.series = series;`;
          if(comp.basicConfigData.xAxis3D.data) {
            res3DStr += `obj.xAxis3D = {data:${bar3DObj.xAxis3D}};` 
          }
  
          if(comp.basicConfigData.yAxis3D.data) {
            res3DStr += `obj.yAxis3D = {data:${bar3DObj.yAxis3D}};` 
          } 
  
          if(comp.basicConfigData.zAxis3D.data) {
            res3DStr += `obj.zAxis3D = {data:${bar3DObj.zAxis3D}};` 
          }
          comp.modelDataFunc = `(data, style) => {${res3DStr} return {data: obj, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data: data, style:style};}`;
        }
        
        comp.option = result;
        break;
      case 'surface':
        result = comp.resultData;
        let surfaceObj = {x: '', y:'', z: ''}
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name == 'X轴') {
              result.series[0].equation.x.step = resData;
              surfaceObj.x = fnData;
            }else if(v.name == 'Y轴') {
              result.series[0].equation.y.step = resData;
              surfaceObj.y = fnData;
            }else if(v.name == 'Z轴') {
              result.series[0].equation.z1 = resData;
              surfaceObj.z = fnData;
            }
          }); 
        })
        if(surfaceObj.x) {
          comp.modelDataFunc = `(data, style) => {return {data: {series: [{equation: {x: {step: ${surfaceObj.x}},y: {step: ${surfaceObj.y}},z1: ${surfaceObj.z1}}}]}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => {return {data: data, style:style};}`;
        }
        
        comp.option = result;
        break;
      default:
        break;
    }
  }else if(comp.basicConfigData.series && typeof comp.basicConfigData.series == 'object') {
    switch(comp.basicConfigData.series.type) {
      case 'bar':
        result = comp.resultData;
        let barObj = {radiusAxis: '', data:''}
        comp.treePropValues.forEach(v => {
          modelCommonCode(res, v, (resData, fnData) => {
            if(v.name.indexOf('数据') != -1) {
              result.series.data = resData;
              barObj.data = fnData;
            }else if(v.name == '半径轴') {
              result.radiusAxis.data = resData;
              barObj.radiusAxis = fnData;
            }
          }); 
        })
        if(barObj.data) {
          comp.modelDataFunc = `(data, style) => {return {data: {radiusAxis: {data: ${barObj.radiusAxis}}, series: {data: ${barObj.data}}}, style:style};}`;
        }else {
          comp.modelDataFunc = `(data, style) => { return {data: data, style:style};}`;
        }
        
        comp.option = result;
        break;
      default:
        break;
    }
  }else if(comp.basicConfigData.graphic && comp.basicConfigData.graphic.elements[0].type == 'text') {
    result = comp.resultData;
    let barObj = {text: ''}
    comp.treePropValues.forEach(v => {
      modelCommonCode(res, v, (resData, fnData) => {
        if(v.name.indexOf('数据') != -1) {
          result.graphic.elements[0].style.text = resData;
          barObj.text = fnData;
        }
      }); 
    })
    if(barObj.text) {
      comp.modelDataFunc = `(data, style) => {return {data: {graphic:{elements: [{style: {text: ${barObj.text}}}]}}, style:style};}`;
    }else {
      comp.modelDataFunc = `(data, style) => {return {data: data, style:style};}`;
    }
    
    comp.option = result;
  }

  return result;
};

const setTransformData = config => {
  let option;
  if (
    refObj.currentNode.value.sourceData.sourceDataType != 3 &&
    refObj.currentNode.value.sourceData.sourceDataType != 1
  ) {
    try {
      option = JSON.parse(refObj.currentNode.value.jsonOption);
    } catch (error) {
      try {
        option = eval(`
      let option=${refObj.currentNode.value.jsonOption};
      option;
      `);
      } catch (error) {
        option = refObj.currentNode.value.jsonOption;
      }
    }
  } else {
    option = config;
  }
  // refObj.currentNode.value.jsonOption = config;
  refObj.currentNode.value.transformData = option;
  option = adaptDataFn(refObj.currentNode.value, config);
  if (
    refObj.currentNode.value.type == "chart" ||
    (refObj.currentNode.value.config && (_.contains(staticObj.unContainKeys.value, refObj.currentNode.value.type) || _.contains(staticObj.otherConfigKey.value, refObj.currentNode.value.type)))
  ) {
    let resultData = unionIDataAndCData(
      option,
      refObj.currentNode.value.basicConfigData
    );
    refObj.currentNode.value.basicConfigData = refObj.currentNode.value.resultData = resultData;
  }else {
    refObj.currentNode.value.basicConfigData = refObj.currentNode.value.resultData = option;
  }

  if(flagObj.dynamicEdit.value || flagObj.repeatCompEdit.value) {
    let list = repeatFn(
      refObj.dynamicComp.value.children,
      v => {
        if (v.id == refObj.currentNode.value.id) {
          v = { ...v, ...refObj.currentNode.value };
        }
        return v;
      }
    );
    refObj.dynamicComp.value.children = list;
  }
  let list = repeatFn(
    screenObj.screen.value.page[screenObj.selectPage.value.id].components,
    v => {
      if (v.id == refObj.currentNode.value.id) {
        v = { ...v, ...refObj.currentNode.value };
      }
      return v;
    }
  );
  screenObj.screen.value.page[
    screenObj.selectPage.value.id
  ].components = list;
};

const setBasicDataById = (id, config) => {
  let list = repeatFn(screenObj.screen.value.page[screenObj.selectPage.value.id].components, v=> {
    if(v.id == id) {
      v.basicConfigData = config;
    }
    return v;
  })
  screenObj.screen.value.page[screenObj.selectPage.value.id].components = list;
};

const setParentParmas = (id, config) => {
  refObj.parentParams.value[id] = config;
};

const chooseOutground = () => {
  flagObj.isListenerActive.value = false;
};

const activeListener = () => {
  flagObj.isListenerActive.value = true;
};

const canvasChange = (id) => {
  if (refObj.stage.value) refObj.stage.value.changeGraph(id);
  if (refObj.scene.value) refObj.scene.value.changeGraph(id);
};

const closeAutoScroll = (id) => {
  if (refObj.stage.value) refObj.stage.value.disableScroll(id);
  if (refObj.scene.value) refObj.scene.value.disableScroll(id);
};

const refreshBimEarth = (id) => {
  if (refObj.stage.value) refObj.stage.value.refreshBim(id);
  if (refObj.scene.value) refObj.scene.value.refreshBim(id);
};

const moveComp = m => {
  if (otherObj.comList) {
    refObj.selectGroup.value.forEach(comp => {
      let left = 0,
        top = 0;
      let hisArray = refObj.historyComps[comp.id];
      if (hisArray && hisArray.length > 0) {
        left = hisArray[hisArray.length - 1].left;
        top = hisArray[hisArray.length - 1].top;
        comp.style.left = left - m.x;
        comp.style.top = top - m.y;
        mouseDownFn(comp, true);
      }
    });
  }
};

const refreshAnimate = (id) => {
  if (refObj.stage.value) refObj.stage.value.refreshAnimate(id);
  if (refObj.scene.value) refObj.scene.value.refreshAnimate(id);
};

const repeatCompFn = (obj, id, fn) => {
  for(let key in obj) {
    if(id == key && fn) {
      fn(obj[key]);
      return;
    }else if(obj[key] && obj[key].viewRefs) {
      repeatCompFn(obj[key].viewRefs, id, fn);
    }
  }
};

const repeatComData = (list, id) => {
  let result = _.filter(list, v => {
    return v.id != id;
  });
  list.forEach(v => {
    if (v.children && v.children.length > 0) {
      v.children = repeatComData(v.children, id);
    }
    return v;
  });
  return result;
};

const funcEvents = (list, block, isChart, echartParams) => {
  if (_.isArray(list)) {
    list.forEach(tar => {
      if (tar.type) {
        let showType = tar.type;
        switch (showType) {
          case 1:
            if (parseInt(tar.delay) > 0) {
              hideTimer = setTimeout(() => {
                changeEventStyle({ id: tar.relyComp.id, type: 0 }, true);
                nextTick(() => {
                  displayComp(tar.relyComp.id, 1, block);
                })
              }, parseInt(tar.delay) * 1000);
            } else {
              changeEventStyle({ id: tar.relyComp.id, type: 0 }, true);
              nextTick(() => {
                displayComp(tar.relyComp.id, 1, block);
              })
            }
            break;
          case 2:
            if (parseInt(tar.delay) > 0) {
              showTimer = setTimeout(() => {
                changeEventStyle({ id: tar.relyComp.id, type: 0 }, true);
                displayComp(tar.relyComp.id, 2, block);

              }, parseInt(tar.delay) * 1000);
            } else {
              changeEventStyle({ id: tar.relyComp.id, type: 0 }, true);
              displayComp(tar.relyComp.id, 2, block);

            }
            break;
          case 3:
            if (parseInt(tar.delay) > 0) {
              linkTimer = setTimeout(() => {
                if (tar.openLink) {
                  let type = tar.openLink.type,
                    url = tar.openLink.url;
                  switch (type) {
                    case "window":
                      iframeWindow.src = url;
                      iframeWindow.show = true;
                      break;
                    case "newTab":
                      window.open(url, "_blank");
                      break;
                    case "iframe":
                      iframeReplace.src = url;
                      iframeReplace.show = true;
                  }
                }
              }, parseInt(tar.delay) * 1000);
            } else {
              if (tar.openLink) {
                let type = tar.openLink.type,
                  url = tar.openLink.url;
                switch (type) {
                  case "window":
                    iframeWindow.src = url;
                    iframeWindow.show = true;
                    break;
                  case "newTab":
                    window.open(url, "_blank");
                    break;
                  case "iframe":
                    iframeReplace.src = url;
                    iframeReplace.show = true;
                }
              }
            }
            break;
          case 4:
            if (parseInt(tar.delay) > 0) {
              show_HideTimer = setTimeout(() => {
                changeEventStyle({ id: tar.relyComp.id, type: 0 }, true);
                displayComp(tar.relyComp.id, 4, block);
              }, parseInt(tar.delay) * 1000);
            } else {
              changeEventStyle({ id: tar.relyComp.id, type: 0 }, true);
              displayComp(tar.relyComp.id, 4, block);
            }
            break;
          case 5:
            let cText = adaptParamData(
              block.jsonCompJumpOption,
              block.params
            );
            let args = {};
            if (!isChart) {
              args = {
                id: block.id,
                pageId: tar.jumpPage.id,
                param: { value: cText }
              }
            }else {
              let option = "";
              try {
                option = JSON.parse(cText);
              } catch (error) {
                option = eval(`
                let option=${cText};
                option;
                `);
              }
              option.value.type = echartParams.seriesType;
              option.value.name = echartParams.name;
              option.value.data = echartParams.value;
              args = {
                id: block.id,
                pageId: tar.jumpPage.id,
                param: { value: JSON.stringify(option) },
                isSeries: tar.jumpPage.type == 1 ? false : true
              }
            }
            if (parseInt(tar.delay) > 0) {
              jumpTimer = setTimeout(() => {
                jumpPage(args);
              }, parseInt(tar.delay) * 1000);
            } else {
              jumpPage(args);
            }
            break;
          case 6:
            if (parseInt(tar.delay) > 0) {
              moveTimer = setTimeout(() => {
                moveDynamicChildren(tar.relyComp);
              }, parseInt(tar.delay) * 1000);
            } else moveDynamicChildren(tar.relyComp);
            break;
          case 7:
            if (parseInt(tar.delay) > 0) {
              refreshTimer = setTimeout(() => {
                eventFreshComp(tar.refreshComp);
              }, parseInt(tar.delay) * 1000);
            } else eventFreshComp(tar.refreshComp);
            break;
          case 8:
            if (parseInt(tar.delay) > 0) {
              paramTimer = setTimeout(() => {
                changeParamValue(block, tar.paramsComp);
              }, parseInt(tar.delay) * 1000);
            } else changeParamValue(block, tar.paramsComp);
            break;
          case 9:
            if (parseInt(tar.delay) > 0) {
              styleTimer = setTimeout(() => {
                changeEventStyle(tar.styleComp);
              }, parseInt(tar.delay) * 1000);
            } else changeEventStyle(tar.styleComp);
            break;
          case 10:
            if (parseInt(tar.delay) > 0) {
              dealTimer = setTimeout(() => {
                dealParamsDataFn(block);
              }, parseInt(tar.delay) * 1000);
            } else dealParamsDataFn(block);
            break;
        }
      }
    });
  }
};

const compMoveFn = (e, block, scale) => {
  let style = block.style,
    type = e.target.className;
  let w = style.width,
    h = style.height,
    l = style.left,
    t = style.top;
  let x1 = e.pageX,
    y1 = e.pageY;

  document.onmousemove = function (e2) {
    let x2 = e2.pageX,
      y2 = e2.pageY;
    let dX = x1 - x2,
      dY = y1 - y2;
    let move = () => {
      moveComp({
        x: (dX / scale) * 100,
        y: (dY / scale) * 100
      });
    },
      res = {
        resTop () {
          style.top = t - (dY / scale) * 100;
          style.height = h + (dY / scale) * 100;
        },
        resRight () {
          style.width = w - (dX / scale) * 100;

        },
        resBottom () {
          style.height = h - (dY / scale) * 100;
        },
        resLeft () {
          style.left = l - (dX / scale) * 100;
          style.width = w + (dX / scale) * 100;
        }
      };
    switch (type) {
      case "move":
        move();
        guideLineChange();
        break;
      case "res-t":
        res.resTop();
        mouseDownFn(block, false);
        break;
      case "res-tr":
        res.resTop();
        res.resRight();
        mouseDownFn(block, false);
        guideLineChange(block);
        // self.anchor = true;
        break;
      case "res-r":
        res.resRight();
        mouseDownFn(block, false);
        guideLineChange(block);
        break;
      case "res-br":
        res.resBottom();
        res.resRight();
        mouseDownFn(block, false);
        guideLineChange(block);
        break;
      case "res-b":
        res.resBottom();
        mouseDownFn(block, false);
        guideLineChange(block);
        break;
      case "res-bl":
        res.resBottom();
        res.resLeft();
        mouseDownFn(block, false);
        guideLineChange(block);
        break;
      case "res-l":
        res.resLeft();
        mouseDownFn(block, false);
        guideLineChange(block);
        break;
      case "res-tl":
        res.resTop();
        res.resLeft();
        mouseDownFn(block, false);
        guideLineChange(block);
    }
  };
  document.onmouseup = () => {
    document.onmousemove = document.onmouseup = null;
    // self.$bus.emit("compColon");
    // if (self.anchor) self.resizeComp();
    // self.clearResizeTimer();
  };
};

//样式提到preview.vue中，变量定义为全局
const guideLineChange = (block) => {
  let w = block.style.width,
    h = block.style.height,
    t = block.style.top,
    l = block.style.left;
  staticObj.guide.pos = [
    [t, t + h / 2, t + h],
    [l, l + w / 2, l + w]
  ];
};

const resizeComp = () => {
  let self = this;
  let obj = _.find(self.unContainKeys, v => {
    return v == self.block.type;
  })
  if (self.block.type == "chart") self.myChart.resize();
  else if (self.block.type == "static" || self.block.type == 'choropleth')
    self.$refs["echartMap" + self.block.id].resizeComp();
  else if (self.block.config && obj) {
    self.block.type = self.block.type + "_";
    self.$nextTick(() => {
      self.block.type = self.key;
    });
  } else if (self.block.type == "three-map") {
    self.$refs["threeMap" + self.block.id].resize();
  } else if (self.block.type == 'graph' || self.block.type == 'pyramid') {
    self.refreshGraph(self.block.id);
  } else if (self.block.type == 'bim_earth') {
    self.refreshBimEarth();
  } else if (self.block.type == 'layout') {
    self.adaptLayoutComp();
  }

  if (self.block.animate) {
    let animateKey = JSON.parse(JSON.stringify(self.block.animate));
    self.block.animate = self.block.animate + "_";
    self.$nextTick(() => {
      self.block.animate = animateKey;
    });
  }
}

export {
  init,
  getScreenData,
  setScreenCompData,
  adaptInitComp,
  compLifeCycle,
  jumpPage,
  adaptParamData,
  replaceParamValue,
  repeatFn,
  unionIDataAndCData,
  displayComp,
  moveDynamicChildren,
  eventFreshComp,
  changeParamValue,
  changeEventStyle,
  dealParamsDataFn,
  adaptCompData,
  getAllPrjModels,
  beforeDealInitCompData,
  dealInitCompData,
  dealPreCompData,
  dealBackCompData,
  getCompParams,
  adaptDataFn,
  setComInfoList,
  changeConfig,
  getParentIds,
  adaptOldData,
  refreshComp,
  choosePage,
  createBoard,
  addChildComp,
  addScreenChildren,
  getJumpCompParams,
  chooseChildComp,
  mouseDownFn,
  composePropValues,
  gainPropValuesInComp,
  getModelContentById,
  setTransformData,
  saveDataV,
  setParentParmas,
  chooseOutground,
  activeListener,
  canvasChange,
  closeAutoScroll,
  refreshBimEarth,
  setBasicDataById,
  moveComp,
  refreshAnimate,
  repeatCompFn,
  repeatComData,

  funcEvents,
  compMoveFn
};
