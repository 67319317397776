const colWhite = [
  "rgba(255,255,255,.05)",
  "rgba(255,255,255,.1)",
  "rgba(255,255,255,.2)",
  "rgba(255,255,255,.3)",
  "rgba(255,255,255,.4)",
  "rgba(255,255,255,.5)",
  "rgba(255,255,255,.6)",
  "rgba(255,255,255,.7)",
  "rgba(255,255,255,.8)",
  "rgba(255,255,255,.9)",
  "rgba(255,255,255,1)"
];

const colList = [
  "rgba(30, 170, 255, 1)",
  "rgba(20, 220, 255, 1)",
  "rgba(75, 245, 235, 1)",
  "rgba(135, 245, 240, 1)",
  "rgba(185, 240, 240, 1)",
  "rgba(230, 250, 250, 1)"
];

const gradualColor = [
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1.5,
    r: 1,
    colorStops: [
      {
        offset: 0,
        color: colList[0]
      },
      {
        offset: 1,
        color: colList[0].replace(", 1)", ", .2)")
      }
    ],
    global: false
  },
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1.5,
    r: 1,
    colorStops: [
      {
        offset: 0,
        color: colList[1]
      },
      {
        offset: 1,
        color: colList[1].replace(", 1)", ", .2)")
      }
    ],
    global: false
  },
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1.5,
    r: 1,
    colorStops: [
      {
        offset: 0,
        color: colList[2]
      },
      {
        offset: 1,
        color: colList[2].replace(", 1)", ", .2)")
      }
    ],
    global: false
  },
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1.5,
    r: 1,
    colorStops: [
      {
        offset: 0,
        color: colList[3]
      },
      {
        offset: 1,
        color: colList[3].replace(", 1)", ", .2)")
      }
    ],
    global: false
  },
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1.5,
    r: 1,
    colorStops: [
      {
        offset: 0,
        color: colList[4]
      },
      {
        offset: 1,
        color: colList[4].replace(", 1)", ", .2)")
      }
    ],
    global: false
  },
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1.5,
    r: 1,
    colorStops: [
      {
        offset: 0,
        color: colList[5]
      },
      {
        offset: 1,
        color: colList[5].replace(", 1)", ", .2)")
      }
    ],
    global: false
  }
];

const generalOption = {
  xAxis: {
    axisLabel: {
      color: colWhite[8],
      fontSize: "16px"
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    },
    splitArea: {
      show: false
    }
  },
  yAxis: {
    name: "",
    nameTextStyle: {
      color: colWhite[6],
      padding: [0, 0, 0, 20]
    },
    axisLabel: {
      color: colWhite[6],
      fontSize: "14px"
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "solid",
        color: colWhite[2]
      }
    },
    axisTick: {
      show: false
    }
  },
  radiusAxis: {
    axisLabel: {
      show: true,
      color: colWhite[8],
      fontSize: "16px"
    },
    axisLine: {
      lineStyle: {
        color: colWhite[2]
      }
    },
    axisTick: {
      lineStyle: {
        color: colWhite[2]
      }
    },
    minorTick: {
      lineStyle: {
        color: colWhite[2]
      }
    }
  }
};

export { colWhite, colList, gradualColor, generalOption };
